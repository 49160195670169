import * as types from './actionTypes'
import axiosClient from './apiClient';

export function getTileCount(id,minZoom,maxZoom) {
    return dispatch => {
        dispatch({ type: types.FETCH_TILE_COUNT });


        return axiosClient.get(`dataset/${id}/count?minZoom=${minZoom}&maxZoom=${maxZoom}`).then(function (res) {
            dispatch({ type: types.FETCH_TILE_COUNT_COMPLETE, result: res.data });
        })
    }
}