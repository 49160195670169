import { createMuiTheme } from '@material-ui/core/styles';
import { lighten, darken } from '@material-ui/core/styles/colorManipulator';

import styles from './scss/variables.scss'

// const BACKGROUND = '#008080';
// const BACKGROUND_SECONDARY = '#262a31';
// const PRIMARY = '#008080';
// const PRIMARY_TEXT = 'white'
// const PRIMARY_TEXT_LIGHT = 'whitesmoke'


const BACKGROUND = styles.primaryBackgroundColor;
const BACKGROUND_SECONDARY = styles.secondaryBackgroundColor;
const PRIMARY = styles.primaryColor;
const PRIMARY_TEXT = styles.primaryTextColor
const PRIMARY_TEXT_LIGHT = 'rgba(0,0,0,0.6)'

let theme = {
    palette: {
        type: 'light',
        background: BACKGROUND,
        secondaryBackground:{
            main: BACKGROUND_SECONDARY,
        },
        primary: {
            main: PRIMARY,
            light: lighten(PRIMARY, 0.2),
            dark: darken(PRIMARY, 0.2),
        },
        secondary: {
            main: PRIMARY,
            light: lighten(PRIMARY, 0.2),
            dark: darken(PRIMARY, 0.2),
        },
        text:{
            main:PRIMARY_TEXT
        }

    },
    overrides: {
        MuiTypography: {
            root: {
                color:PRIMARY_TEXT
            }
        },
        MuiSelect: {
            root: {
                width: '100%'
            },
            select: {
                color: PRIMARY_TEXT,
                width: '100%'
            },
            icon: {
                color: PRIMARY_TEXT
            }

        },
        MuiTableCell:{
            body:{
                color:PRIMARY_TEXT
            }
        },
        MuiTableSortLabel:{
            root:{
                color:PRIMARY_TEXT
            }
        },
        MuiTablePagination:{
            root:{
                color:PRIMARY_TEXT
            },
            actions:{
                color:PRIMARY_TEXT
            }
        },
        MuiFormControlLabel: {
            label: {
                color: PRIMARY_TEXT
            }
        },
        MuiCheckbox: {
            root: {
                color: 'gray',
                '&$checked': {
                    color: PRIMARY_TEXT + '!important',
                },
            },
        },
        MuiInput: {
            root: {
                color: PRIMARY_TEXT,              
            },
            underline: {
                '&:before': {
                    'border-bottom': 'gray 1px solid'
                },
                '&:hover:before': {
                    'border-bottom': 'lightgray 1px solid !important'
                },
                '&:after': {
                    'border-bottom': PRIMARY + ' 2px solid'
                }

            },
           
        },
        MuiFilledInput:{
            root: {
                backgroundColor:'transparent'               
            },
        },
        MuiFormLabel: {
            root: {
                color: PRIMARY_TEXT,
                "&$focused": { // increase the specificity for the pseudo class
                    color: PRIMARY
                }
            },
        },
        MuiFormControl: {
            root: {
                color: 'gray',
            }
        },
        MuiMenu:{
            paper:{
                backgroundColor:'white'
            }
        },
        MuiMenuItem:{
            root:{
                color:PRIMARY_TEXT
            }
        },
        MuiButton: {
            contained: {
                color: 'white',
            
            }
        },
        MuiLinearProgress:{
            colorPrimary:{
                backgroundColor:'transparent'
            }
        },
        MuiAppBar:{
            root:{
                boxShadow:'none',
            }
        },
        MuiToolbar:{
            root:{
                paddingLeft:'16px!important',
               
            }
        },
        MuiTooltip:{
            tooltip:{
                fontSize:12
            }
        },
        MuiTab:{
            wrapper:{
                color:PRIMARY_TEXT
            },
            root:{
                backgroundColor:BACKGROUND_SECONDARY
            }
        }
    }

}

export default createMuiTheme(theme);