import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers/index';
import authMiddleware from './middleware/authMiddleware';

import { createHashHistory } from 'history'

let middlewares = [];

middlewares.push(authMiddleware);

middlewares.push(thunk);

let middleware = applyMiddleware(...middlewares);

// add the redux dev tools
if (process.env.NODE_ENV !== 'production' && window.devToolsExtension) {
    middleware = compose(middleware, window.devToolsExtension());
}

let history = createHashHistory({
    /* pass a configuration object here if needed */
})

const store = createStore(
    reducers,
    middleware
);

export {store,history}