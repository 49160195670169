import React, { useState } from 'react';


export default function LayerType(props) {

    function renderType(type){
        switch (type){
            case 'fill':
            case 'line':
            case 'circle':
                return  <div className={props.type} style={{ backgroundColor: props.color }}></div>
            case 'symbol':
                return <div className={props.type} style={{ color: props.color }}>A</div>
        }
    }

    return (
        <div className="type">
            {renderType(props.type)}
        </div>
    )
}