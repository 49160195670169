import * as types from "../actions/actionTypes";

const datasetsInit = {
    fetching: true,
    data: [],
    selected: {}
};

export function datasetsReducer(state = datasetsInit, action) {
    switch (action.type) {
        case types.FETCH_DATASETS:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_DATASETS_COMPLETE:
            return {
                ...state,
                fetching: false,
                data: [...action.result]
            };
        case types.SELECT_DATASET:
            return {
                ...state,
                selected: action.result
            };
        case types.DELETE_DATASET:
            return {
                ...state,
                fetching: true
            };
        case types.DELETE_DATASET_COMPLETE:
            return {
                ...state,
                fetching: false,
                data: state.data.filter(x => x.id !== action.result)
            };
        case types.CREATE_DATASET_COMPLETE:
            return {
                ...state,
                fetching: false,
                data: [...state.data,action.result]
            };
        default:
            return state;
    }
}

const datasetDetailsInit = {
    fetching: true,
    generatingCache: false,
    tileCount: 0,
    dataset: { columnsToShow: "", columns: "", cached: true }
};

export function datasetDetailsReducer(state = datasetDetailsInit, action) {
    switch (action.type) {
        case types.FETCH_DATASET_DETAILS:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_DATASET_DETAILS_COMPLETE:
            return {
                ...state,
                fetching: false,
                dataset: action.result
            };
        case types.FETCH_TILE_COUNT:
            return {
                ...state,
                fetching: false
            };
        case types.FETCH_TILE_COUNT_COMPLETE:
            return {
                ...state,
                fetching: false,
                tileCount: action.result
            };
        case types.CREATE_CACHE:
            return {
                ...state,
                generatingCache: true
            };
        case types.CREATE_CACHE_COMPLETE:
            return {
                ...state,
                generatingCache: false
            };
        default:
            return state;
    }
}
