import * as types from './actionTypes'
import axiosClient from './apiClient';

export function toggleMapDrawing() {
    return dispatch => {
        dispatch({ type: types.MAP_DRAWING_TOGGLED });
    }
}

export function updateLayer(layer) {
    return dispatch => {
        dispatch({ type: types.MAP_UPDATE_LAYER, result:layer });
    }
}

export function addLayer(layer) {
    return dispatch => {
        dispatch({ type: types.MAP_ADD_LAYER, result:layer });

    }
}

export function addLayers(layers) {
    return dispatch => {
        dispatch({ type: types.MAP_ADD_LAYERS, result: layers });
    };
}


export function swapLayer(layer) {
    return dispatch => {
        dispatch({ type: types.MAP_SWAP_LAYER, result:layer });
    }
}

export function removeLayerAsync(layer) {
    return dispatch => {
        dispatch({ type: types.MAP_REMOVE_LAYER, result:layer });
        return Promise.resolve();
    }
}

export function removeAllLayers(layer) {
    return dispatch => {
        dispatch({ type: types.MAP_REMOVE_ALL_LAYERS, result:layer });
        return Promise.resolve();
    }
}


export function setLayerType(type) {
    return dispatch => {
        dispatch({ type: types.MAP_SET_LAYER_TYPE, result:type });
    }
}

export function addPaint(...paint) {
    return dispatch => {
        dispatch({ type: types.MAP_ADD_PAINT, result:paint });
    }
}

export function updatePaint(paint) {
    return dispatch => {
        dispatch({ type: types.MAP_UPDATE_PAINT, result:paint });
    }
}

export function addLayout(layout) {
    return dispatch => {
        dispatch({ type: types.MAP_ADD_LAYOUT, result:layout });
    }
}

export function updateLayout(layout) {
    return dispatch => {
        dispatch({ type: types.MAP_UPDATE_LAYOUT, result:layout });
    }
}

export function jumpTo(options) {
    return dispatch => {
        dispatch({ type: types.MAP_JUMP_TO, result:options });
    }
}

export function fitBounds(bbox,options) {
    return dispatch => {
        dispatch({ type: types.MAP_FIT_BOUNDS, result:{bbox,options} });
    }
}