import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as mapServiceActions from '../../../actions/mapService';
import { Route } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';


import Map from '../../../components/map/mapComponent'
import MapsListView from '../mapsListView/mapsListView'
import EditView from '../mapEditView/mapEditView'
import StyleView from '../../styleEditor/editStyleView/editStyleView'
import NavigationMenu from '../../../components/navigationMenu'

const styles = theme => ({

});


class MapsRootView extends Component {

    constructor(props) {
        super(props);
    
      }

    componentDidMount() {
        this.props.getMaps()
    }

    componentDidUpdate(prevProps){

    }

    render() {
        let { classes, match } = this.props;

        return (
            <div className="maps-rootview">
                <NavigationMenu/>
                <div className="sidebar">
                    <Route exact path={`${match.path}/`} component={MapsListView} />
                    <Route path={`${match.path}/edit/:mapId`} component={EditView} />
                    <Route path={`${match.path}/style/:mapId`} component={StyleView} />
                </div>

                <div className="map">
                    <Map />
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getMaps: () => dispatch(mapServiceActions.getMapsAdmin()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(MapsRootView));

