import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import AddIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";

import * as groupsActions from "../../../actions/groupService";
import * as mapActions from "../../../actions/map";
import { history } from "../../../store";
import { toastr } from "react-redux-toastr";
import CreateModal from "./createGroupModal";

const styles = theme => ({
    title: {
        flexGrow: 1
    }
});

class GroupsListView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            groups: [],
            selected: props.match.params.groupId || null,
            modalOpen: false,
            search: ""
        };
    }

    componentDidMount() {
        this.props.getGroups();
    }

    groupOnClick(item) {
        this.setState({
            selected: item.id
        });
        history.push("/groups/edit/" + item.id);
    }

    // onEditClick = (item) => {
    //     history.push('/datasets/edit/' + item.datasetId)
    // }

    // onClickDeleteDataset(item) {
    //     const toastrConfirmOptions = {
    //         onOk: () => this.deleteDataset(item),
    //         onCancel: () => console.log('CANCEL: clicked')
    //     };
    //     toastr.confirm('Delete dataset ' + item.name, toastrConfirmOptions);
    // }

    onAddGroupClick = () => {
        this.setState({
            modalOpen: true
        });
    };

    onModalClose = () => {
        this.setState({
            modalOpen: false
        });
    };

    onDeleteGroup(group) {
        const toastrConfirmOptions = {
            onOk: () => this.deleteGroup(group),
            onCancel: () => console.log("CANCEL: clicked")
        };
        toastr.confirm(`Delete Group: ${group.name}?`, toastrConfirmOptions);
    }

    deleteGroup(group) {
        this.props
            .deleteGroup(group.id)
            .then(res => {
                toastr.success("Success", "Group deleted");
            })
            .catch(err => {
                toastr.error("Error", err.message);
            });
    }

    onSearch = e => {
        this.setState({
            search: e.target.value
        });
    };

    render() {
        let { classes } = this.props;

        let groups = this.props.groupsState.groups
            .filter(x => x.name.includes(this.state.search))
            .slice(0, 10)
            .map((item, index) => {
                let selected = item.id === this.state.selected;

                return (
                    <div key={index} className={selected ? "group selected" : "group"} onDoubleClick={() => this.onEditClick(item)} onClick={() => this.groupOnClick(item)}>
                        <div className="name">{item.name}</div>
                        <Tooltip id="tooltip-top" title="Delete">
                            <div className="button" onClick={() => this.onDeleteGroup(item)}>
                                <DeleteIcon className="icon" />
                            </div>
                        </Tooltip>
                    </div>
                );
            });

        return (
            <div className="sidebar-container group-overview">
                <CreateModal open={this.state.modalOpen} handleClose={this.onModalClose} />
                <AppBar position="static">
                    <Toolbar className="toolbar">
                        <img src="/Logo.svg"></img>
                    </Toolbar>
                </AppBar>
                <div className="header">
                    <Typography variant="h6" className={classes.title}>
                        Groups
                    </Typography>
                    <Button color="primary" variant="contained" onClick={this.onAddGroupClick}>
                        <AddIcon style={{ marginRight: 8, marginLeft: -8 }} />
                        Add Group
                    </Button>
                </div>
                {this.props.groupsState.fetching && <LinearProgress className="no-margin-progress" />}
                <div className="content">
                    <div className="search">
                        <TextField
                            id="full-width"
                            inputProps={{ autoComplete: "off" }}
                            placeholder="Search groups"
                            value={this.state.search}
                            onChange={this.onSearch}
                            fullWidth
                            margin="dense"
                        />
                    </div>
                    <div className="item-container">{groups}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        datasets: state.datasets,
        groupsState: state.groups
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getGroups: () => dispatch(groupsActions.getGroups()),
        deleteGroup: groupId => dispatch(groupsActions.deleteGroup(groupId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupsListView));
