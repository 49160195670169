const colors = [
    "#ef5350",
    "#EC407A",
    "#AB47BC",
    "#7E57C2",
    "#5C6BC0",
    "#42A5F5",
    "#29B6F6",
    "#26C6DA",
    "#26A69A",
    "#66BB6A",
    "#9CCC65",
    "#D4E157",
    "#FFEE58",
    "#FFCA28",
    "#FFA726",
    "#FF7043",
    "#8D6E63",
    "#BDBDBD",
    "#78909C"
];

export function getColor(index) {
 

    return colors[index % colors.length];
}

export function getColors(count) {
    let colorArray = [];

    let colorIndex = 0;
    for (let i = 0; i < count; i++) {
        colorArray.push(colors[colorIndex]);

        if (colorIndex >= colors.length) {
            colorIndex = 0;
        } else {
            colorIndex = i;
        }
    }

    return colorArray;
}

export function interpolateColors(color1, color2, steps) {
    let colorArray = [];

    let stepValue = 1.0 / (steps + 1) ;

    for (let ratio = 0.0; ratio <= 1.0; ratio += stepValue) {
        colorArray.push(interpolateColor(color1, color2, ratio));
    }

    return colorArray;
}

function interpolateColor(a, b, amount) {
 

    var ah = parseInt(a.replace(/#/g, ''), 16),
    ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,
    bh = parseInt(b.replace(/#/g, ''), 16),
    br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,
    rr = ar + amount * (br - ar),
    rg = ag + amount * (bg - ag),
    rb = ab + amount * (bb - ab);

return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
}

function toHex(x) {
    x = x.toString(16);
    return x.length == 1 ? "0" + x : x;
}

console.log(interpolateColors("#FFFFFF", "#013220", 3));
