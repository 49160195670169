import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import RemoveIcon from "@material-ui/icons/Remove";
import InputBase from "@material-ui/core/InputBase";
import MoreIcon from "@material-ui/icons/MoreHoriz";

import Toolbar from "./propertyToolbar";

function TextProperty(props) {
    let options = [];

    function handleChange(e) {
        props.onPropertyChanged(e.target.value);
    }

    return (
        <div className="property">
            <div className="property-content">
                {props.editable && <TextField className="textfield" onChange={props.onTitleChanged} value={props.title} />}
                {!props.editable && (
                    <Typography variant="body1" className="name">
                        {props.title}
                    </Typography>
                )}
                <div className="text-container">
                    <InputBase value={props.value} onChange={handleChange} className="text" />
                </div>
            </div>
        </div>
    );
}

export default TextProperty;
