import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { history } from "../../../store";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import DragDropIcon from "@material-ui/icons/SaveAlt";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";

import * as datasetsActions from "../../../actions/datasets";
import * as UserServiceActions from "../../../actions/userService";
import * as groupActions from "../../../actions/groupService";
import { toastr } from "react-redux-toastr";

const styles = theme => ({});

class CreateGroupModal extends Component {
    state = {
        name: "",
        validated: { name: true}
    };

    componentDidMount() {}

    changeName = e => {
        let nameValid = e.target.value != "";

        this.setState({
            name: e.target.value,
            validated: {
                ...this.state.validated,
                name: nameValid
            }
        });
    };


    validateInfo() {
        let nameValid = this.state.name != "";

        this.setState({
            validated: {
                name: nameValid,             
            }
        });

        return nameValid
    }

    createGroup = () => {
        if (!this.validateInfo()) {
            return;
        }

        let request = {
            name: this.state.name,
        };

        this.props.createGroup(request).then(
            res => {
                this.props.handleClose();
                toastr.success("Success", "Group created");
            },
            err => {
                toastr.error("Error", err.message);
            }
        );
    };

    render() {
        let { props } = this;
        let { classes } = props;
        return (
            <div>
                <Modal
                    className="atlas-modal create-group-modal"
                    open={props.open}
                    onClose={props.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500
                    }}
                >
                    <Fade in={props.open}>
                        <div className="container">
                            <Typography variant="h6" id="transition-modal-title" className="header">
                                Create Group
                            </Typography>
                            <div className="page">
                                <div className="section">
                                    <Typography variant="body1">Name</Typography>
                                    <TextField
                                        id="full-width"
                                        placeholder="name"
                                        value={this.state.name}
                                        onChange={this.changeName}
                                        fullWidth
                                        error={!this.state.validated.name}
                                        margin="none"
                                    />
                                </div>

                                <div className="button-container">
                                    <Button color="primary" variant="outlined" onClick={this.createGroup}>
                                        Create
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        createGroup: createGroupRequest => dispatch(groupActions.createGroup(createGroupRequest))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreateGroupModal));
