import React, { Component } from "react";
import { connect } from "react-redux";
import * as datasetsActions from "../../../actions/datasets";
import { Route } from "react-router-dom";
import Table from "./components/table";
import LinearProgress from "@material-ui/core/LinearProgress";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({});

class TableView extends Component {
    state = {
        rows: [],
        data: []
    };

    componentDidMount() {
        this.props.getGeoJson(this.props.tableViewState.datasetId).then(res => {
            let rows = this.generateRows(res.result[0].properties);
            this.setState({
                rows:rows
            })
           })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tableViewState.datasetId !== this.props.tableViewState.datasetId) {
            
           this.props.getGeoJson(this.props.tableViewState.datasetId).then(res => {
            let rows = this.generateRows(res.result[0].properties);
            this.setState({
                rows:rows
            })
           })
        }
    }

    generateRows(properties) {
        let rows = [];
        for (let [key, value] of Object.entries(properties)) {
            rows.push({
                id: key,
                label: key,
                disablePadding: false
            });
        }
        return rows;
    }

    onItemClick = (item) => {
        console.log(item)
    }

    render() {
        let { classes, match } = this.props;

        return (
            <div className="table-view">
                <Table
                    title={"Database view"}
                    fetching={this.props.tableViewState.fetching}
                    data={this.props.tableViewState.features}
                    rows={this.state.rows}
                    onAddClick={this.onAddClick}
                    onDeleteClick={this.onDeleteClick}
                    onRefreshClick={this.onRefreshClick}
                    onItemClick={this.onItemClick}
                    onEditClick={this.onTaskEditClick}
                    onRunClick={this.onTaskRunClick}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        tableViewState: state.tableView
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getGeoJson: datasetId => dispatch(datasetsActions.getAsGeoJson(datasetId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TableView));
