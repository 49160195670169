import * as types from "../actions/actionTypes";

const mapServiceInit = {
    fetching: false,
    maps: [],
    selected: {}
};

export function mapServiceReducer(state = mapServiceInit, action) {
    switch (action.type) {
        case types.FETCH_MAPS:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_MAPS_COMPLETE:
            return {
                ...state,
                fetching: false,
                maps: action.result
            };
        case types.CREATE_MAP_COMPLETE:
            return {
                ...state,
                fetching: false,
                maps: [...state.maps, action.result]
            };
        case types.DELETE_MAP_COMPLETE:
            return {
                ...state,
                fetching: false,
                maps: removeFromArray(state.maps, action.result)
            };
        case types.GENERATE_MAP_CACHE_COMPLETE:
            return {
                ...state,
                fetching: false,
                maps: updateCaching(state.maps, action.result)
            };
        default:
            return state;
    }
}

function removeFromArray(array, item) {
    return array.filter(x => item.id !== x.id);
}

function updateCaching(array, item) {
    return array.map(map => {
        if (map.id === item.id){
            return{
                ...map,
                cached:true
            }
        }
        return map;
    });
}