import * as types from "./actionTypes";
import axiosClient from "./apiClient";

export function getGroups() {
    return dispatch => {
        dispatch({ type: types.FETCH_GROUPS });

        return axiosClient.get("admin/group").then(function(res) {
            return dispatch({ type: types.FETCH_GROUPS_COMPLETE, result: res.data });
        });
    };
}

export function getGroup(groupId) {
    return dispatch => {
        dispatch({ type: types.FETCH_GROUP });

        return axiosClient.get("group/" + groupId).then(function(res) {
            return dispatch({ type: types.FETCH_GROUP_COMPLETE, result: res.data });
        });
    };
}

export function createGroup(createGroupRequest) {
    return dispatch => {
        dispatch({ type: types.CREATE_GROUP });

        return axiosClient.post("group", createGroupRequest).then(function(res) {
            return dispatch({
                type: types.CREATE_GROUP_COMPLETE,
                result: { id: res.data.id, name: createGroupRequest.name }
            });
        });
    };
}

export function updateGroup(group) {
    return dispatch => {
        dispatch({ type: types.UPDATE_GROUP });

        return axiosClient.put("admin/group/" + group.id, group).then(
            res => {
                return dispatch({ type: types.UPDATE_GROUP_COMPLETE, result: group });
            },
            err => {
                dispatch({ type: types.GROUP_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function deleteGroup(groupId) {
    return dispatch => {
        dispatch({ type: types.DELETE_GROUP });

        return axiosClient.delete("group/" + groupId).then(
            res => {
                return dispatch({ type: types.DELETE_GROUP_COMPLETE, result: { id: groupId } });
            },
            err => {
                dispatch({ type: types.GROUP_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function addGroupUser(groupId,userId) {
    return dispatch => {
        dispatch({ type: 'none' });

        return axiosClient.post(`group/${groupId}/user/${userId}`).then(
            res => {
                return dispatch({ type: 'none', result: { id: groupId } });
            },
            err => {
                dispatch({ type: 'none', result: err });
                throw err;
            }
        );
    };
}

export function removeGroupUser(groupId,userId) {
    return dispatch => {
        dispatch({ type: 'none' });

        return axiosClient.delete(`group/${groupId}/user/${userId}`).then(
            res => {
                return dispatch({ type: 'none', result: { id: groupId } });
            },
            err => {
                dispatch({ type: 'none', result: err });
                throw err;
            }
        );
    };
}
