import * as types from "./actionTypes";
import axiosClient from "./apiClient";

export function getMaps() {
    return dispatch => {
        dispatch({ type: types.FETCH_MAPS });

        return axiosClient.get("map").then(function(res) {
            dispatch({ type: types.FETCH_MAPS_COMPLETE, result: res.data });
        });
    };
}

export function getMapsAdmin() {
    return dispatch => {
        dispatch({ type: types.FETCH_MAPS });

        return axiosClient.get("admin/map").then(function(res) {
            return dispatch({ type: types.FETCH_MAPS_COMPLETE, result: res.data });
        });
    };
}

export function getMap(mapId) {
    return dispatch => {
        dispatch({ type: types.FETCH_MAP });

        return axiosClient.get("map/" + mapId).then(function(res) {
            return dispatch({ type: types.FETCH_MAP_COMPLETE, result: res.data });
        });
    };
}

export function updateMap(mapId, map) {
    return dispatch => {
        dispatch({ type: types.UPDATE_MAP });

        return axiosClient.put("map/" + mapId, map).then(function(res) {
            return dispatch({ type: types.UPDATE_MAP_COMPLETE, result: res.data });
        });
    };
}

export function addDatasetToMap(mapId, datasetId) {
    return dispatch => {
        dispatch({ type: types.FETCH_MAP });

        return axiosClient.post("map/" + mapId + "/dataset/" + datasetId).then(function(res) {
            return dispatch({ type: types.FETCH_MAP_COMPLETE, result: res.data });
        });
    };
}

export function removeDatasetFromMap(mapId, datasetId) {
    return dispatch => {
        dispatch({ type: types.FETCH_MAP });

        return axiosClient.delete("map/" + mapId + "/dataset/" + datasetId).then(function(res) {
            return dispatch({ type: types.FETCH_MAP_COMPLETE, result: res.data });
        });
    };
}

export function getMapStyles(mapId) {
    return dispatch => {
        dispatch({ type: types.FETCH_MAP_STYLE });

        return axiosClient.get("map/" + mapId + "/style").then(function(res) {
            return dispatch({
                type: types.FETCH_MAP_STYLE_COMPLETE,
                result: res.data
            });
        });
    };
}

export function createMap(createMapRequest) {
    return dispatch => {
        dispatch({ type: types.CREATE_MAP });

        return axiosClient.post("map", createMapRequest).then(function(res) {
            return dispatch({
                type: types.CREATE_MAP_COMPLETE,
                result: res.data
            });
        });
    };
}

export function deleteMap(mapId) {
    return dispatch => {
        dispatch({ type: types.DELETE_MAP });

        return axiosClient.delete("map/" + mapId).then(function(res) {
            dispatch({ type: types.DELETE_MAP_COMPLETE, result: { id: mapId } });
        });
    };
}

export function updateMapStyle(mapId, styleId, style) {
    return dispatch => {
        dispatch({ type: types.UPDATE_MAP_STYLE });

        return axiosClient.put("map/" + mapId + "/style/" + styleId, style).then(function(res) {
            return dispatch({
                type: types.UPDATE_MAP_STYLE_COMPLETE,
                result: res.data
            });
        });
    };
}

export function generateCache(mapId) {
    return dispatch => {
        dispatch({ type: types.GENERATE_MAP_CACHE });

        return axiosClient.post("map/" + mapId + "/generate").then(function(res) {
            return dispatch({ type: types.GENERATE_MAP_CACHE_COMPLETE, result: {id:mapId} });
        });
    };
}
