import * as types from "../actions/actionTypes";

const mapInit = {
    drawing: false,
    jumpLocation: { center: [-122.420679, 37.772537], zoom: 10, bearing: 90, pitch: 0 },
    fitBounds: { bbox: [], options: {} },
    layers: [],
    paints: [],
    layouts: []
};

export function mapReducer(state = mapInit, action) {
    switch (action.type) {
        case types.MAP_DRAWING_TOGGLED:
            return {
                ...state,
                drawing: !state.drawing
            };
        case types.MAP_ADD_LAYER:
            return {
                ...state,
                layers: [...state.layers, action.result]
            };
        case types.MAP_ADD_LAYERS:
            return {
                ...state,
                layers: [...state.layers, ...action.result]
            };
        case types.MAP_UPDATE_LAYER:
            return {
                ...state,
                layers: updateArraySetChanged(state.layers, action.result),
                paints: removeFromArray(state.paints, action.result),
                layouts: removeFromArray(state.layouts, action.result)
            };
        case types.MAP_SWAP_LAYER:
            return {
                ...state,
                layers: updateArraySetChanged(state.layers, action.result),
                paints: setChanged(state.paints, action.result),
                layouts: setChanged(state.layouts, action.result)
            };
        case types.MAP_REMOVE_LAYER:
            return {
                ...state,
                layers: removeFromArray(state.layers, action.result),
                paints: removeFromArray(state.paints, action.result),
                layouts: removeFromArray(state.layouts, action.result)
            };
        case types.MAP_REMOVE_ALL_LAYERS:
            return {
                ...state,
                layers: [],
                paints: [],
                layouts: []
            };
        case types.MAP_ADD_PAINT:
            return {
                ...state,
                paints: [
                    ...state.paints,
                    ...action.result.map(x => {
                        return { ...x, changed: true };
                    })
                ]
            };
        case types.MAP_UPDATE_PAINT:
            return {
                ...state,
                paints: updateArray(state.paints, action.result)
            };
        case types.MAP_ADD_LAYOUT:
            return {
                ...state,
                layouts: [...state.layouts, action.result]
            };
        case types.MAP_UPDATE_LAYOUT:
            return {
                ...state,
                layouts: updateArray(state.layouts, action.result)
            };
        case types.MAP_JUMP_TO:
            return {
                ...state,
                jumpLocation: action.result
            };
        case types.MAP_FIT_BOUNDS:
            return {
                ...state,
                fitBounds: action.result
            };
        default:
            return state;
    }
}

function updateArray(array, item) {
    return array.map(x => {
        if (item.layerId === x.layerId) return { ...item };
        return { ...x };
    });
}

function updateArraySetChanged(array, item) {
    return array.map(x => {
        if (item.layerId === x.layerId) return { ...item, changed: true };
        return { ...x, changed: false };
    });
}

function removeFromArray(array, item) {
    return array.filter(x => item.layerId !== x.layerId);
}

function setChanged(array, layer) {
    return array.map(x => {
        if (layer.layerId === x.layerId) return { ...x, changed: true };
        return { ...x, changed: false };
    });
}
