import * as types from "../actions/actionTypes";

const groupsInit = {
    fetching: false,
    groups: []
};

export function groupsReducer(state = groupsInit, action) {
    switch (action.type) {
        case types.FETCH_GROUPS:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_GROUPS_COMPLETE:
            return {
                ...state,
                fetching: false,
                groups: action.result
            };
        case types.CREATE_GROUP:
            return {
                ...state,
                fetching: true
            };
        case types.CREATE_GROUP_COMPLETE:
            return {
                ...state,
                fetching: false,
                groups: [...state.groups, action.result]
            };
        case types.UPDATE_GROUP:
            return {
                ...state,
                fetching: true
            };
        case types.UPDATE_GROUP_COMPLETE:
            return {
                ...state,
                fetching: false,
                groups: updateArray(state.groups, action.result)
            };
        case types.DELETE_GROUP:
            return {
                ...state,
                fetching: true
            };
        case types.DELETE_GROUP_COMPLETE:
            return {
                ...state,
                fetching: false,
                groups: removeFromArray(state.groups, action.result)
            };
        case types.GROUP_ACTION_FAILED:
            return {
                ...state,
                fetching: false,
            };
        default:
            return state;
    }
}

function removeFromArray(array, item) {
    return array.filter(x => item.id !== x.id);
}

function updateArray(array, item) {
    return array.map(x => {
        if (item.id === x.id) {
            return item;
        }
        return x;
    });
}
