import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as UserService from '../../../actions/userService';
import { Route } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';


import UsersListView from '../usersListView/usersListView'
import EditView from '../userEditView/userEditView'
import NavigationMenu from '../../../components/navigationMenu'

const styles = theme => ({

});


class GroupsRootView extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        this.props.getUsers()
    }


    render() {
        let { classes, match } = this.props;

        return (
            <div className="users-rootview">
                <NavigationMenu />
                <div className="sidebar">
                    <UsersListView />
                </div>

                <div className="user-edit-container">
                    <Route exact path={`${match.path}/edit/:userId`} component={EditView} />
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getUsers: () => dispatch(UserService.getUsers())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(GroupsRootView));

