import * as types from "./actionTypes";
import axiosClient from "./apiClient";

export function getUsers() {
    return dispatch => {
        dispatch({ type: types.FETCH_USERS });

        return axiosClient.get("admin/user").then(
            res => {
                return dispatch({ type: types.FETCH_USERS_COMPLETE, result: res.data });
            },
            err => {
                dispatch({ type: types.USER_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function getUser(userId) {
    return dispatch => {
        dispatch({ type: types.FETCH_USER });

        return axiosClient.get("admin/user/" + userId).then(
            res => {
                return dispatch({ type: types.FETCH_USER_COMPLETE, result: res.data });
            },
            err => {
                dispatch({ type: types.USER_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function createUser(createUserRequest) {
    return dispatch => {
        dispatch({ type: types.CREATE_USER });

        return axiosClient.post("admin/user", createUserRequest).then(
            res => {
                return dispatch({
                    type: types.CREATE_USER_COMPLETE,
                    result: { id: res.data.id, username: createUserRequest.username, roles: createUserRequest.roles }
                });
            },
            err => {
                dispatch({ type: types.USER_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function updateUser(user) {
    return dispatch => {
        dispatch({ type: types.UPDATE_USER });

        return axiosClient.put("admin/user/" + user.id, user).then(
            res => {
                return dispatch({ type: types.UPDATE_USER_COMPLETE, result: user });
            },
            err => {
                dispatch({ type: types.USER_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function deleteUser(userId) {
    return dispatch => {
        dispatch({ type: types.DELETE_USER });

        return axiosClient.delete("admin/user/" + userId).then(
            res => {
                return dispatch({ type: types.DELETE_USER_COMPLETE, result: { id: userId } });
            },
            err => {
                dispatch({ type: types.USER_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function changePassword(userId,password) {
    return dispatch => {
        dispatch({ type: types.UPDATE_USER_PASSWORD });

        return axiosClient.put("auth/password",{userId,password}).then(
            res => {
                return dispatch({ type: types.UPDATE_USER_PASSWORD_COMPLETE, result: { id: userId } });
            },
            err => {
                dispatch({ type: types.USER_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}
