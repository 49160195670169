import React, { Component } from "react";
import { connect } from "react-redux";
import GlMap from "mapbox-gl";
import config from "../../config";
// import InfoBox from '../infoBox/infoBoxComponent';
//import MapboxDraw from '@mapbox/mapbox-gl-draw'
//import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import * as mapActions from "../../actions/map";
//import mapStyle from '../../map-style.json';

var MAPBOX_APIKEY = config.mapboxApiKey;

let transformDict = {
    color: x => x,
    "multi-color": x => x,
    number: x => parseFloat(x),
    text: x => x,
    select: x => x
};
var drawObject;

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapLoaded: false,
            selectedFeatures: []
        };
    }

    componentDidMount() {
        // window.addEventListener("resize", this._resize);
        let map = document.getElementById("map");
        var ro = new ResizeObserver(entries => {
            this._resize();
        });

        // Observe one or multiple elements
        ro.observe(map);

        GlMap.accessToken = MAPBOX_APIKEY;

        this.map = new GlMap.Map({
            container: "map",
            style: config.basemap,
            zoom: 1.5,
            center: [15, 40],
            transformRequest: (url, resourceType) => {
                if (resourceType == "Tile" && url.startsWith(config.apiUrl)) {
                    return {
                        url: url + "?key=" + this.props.auth.token
                        // headers: { 'Authorization': 'Bearer ' + this.props.auth.token }
                    };
                }
            }
        });

        this.map.on("load", () => this._mapLoad());
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this._resize);
        this.map.remove();
    }

    _mapLoad = () => {
        // map.mapObject.on('click', (e) => this._onMapClick(e));
        // map.mapObject.on('draw.create', this._drawnFeaturesChanged);
        // map.mapObject.on('draw.delete', this._drawnFeaturesChanged);
        // map.mapObject.on('draw.update', this._drawnFeaturesChanged);

        this.setState({
            mapLoaded: true
        });
    };

    _drawnFeaturesChanged(e) {
        var data = e.features;
    }

    _resize = () => {
        this.map.resize();
    };

    _onViewportChange = viewport => this.setState({ viewport });

    _onStyleChange = mapStyle => this.setState({ mapStyle });

    _onMapClick = e => {
        // if (!this.props.mapState.drawing) {
        //     var features = map.mapObject.queryRenderedFeatures(e.point, {});
        //     this.setState({
        //         ...this.state,
        //         selectedFeatures: features
        //     })
        // }
    };

    showDrawingTools() {
        // if (this.props.mapState.drawing) {
        //     drawObject = new MapboxDraw({
        //         displayControlsDefault: false,
        //         controls: {
        //             polygon: true,
        //             trash: true
        //         }
        //     });
        //     map.mapObject.addControl(drawObject, 'top-right');
        // }
        // else if (drawObject !== undefined) {
        //     map.mapObject.removeControl(drawObject);
        //     drawObject = undefined;
        // }
    }
    addLayers(drawBefore, layers) {
        let mapLayers = this.map.getStyle().layers;

        let firstSymbolId;
        for (let i = 0; i < mapLayers.length; i++) {
            if (mapLayers[i].id === drawBefore) {
                firstSymbolId = mapLayers[i].id;
                break;
            }
        }

        for (let i = 0; i < layers.length; i++) {
            let layer = layers[i];

            if (!this.map.getSource(layer.sourceId)) {
                this.map.addSource(layer.sourceId, {
                    type: "vector",
                    tiles: [config.apiUrl + `tile/${layer.sourceId}/{z}/{x}/{y}`],
                    minzoom: layer.minZoom,
                    maxzoom: layer.maxZoom
                });
            }

            var vectorSource = {
                id: layer.layerId,
                type: layer.type,
                source: layer.sourceId,
                "source-layer": layer.sourceName,
                minzoom: layer.minZoom,
                paint: {}
            };
            this.map.addLayer(vectorSource, firstSymbolId);
            firstSymbolId = layer.layerId;
        }
    }

    removeLayers(previousLayers, currentLayers) {
        if (!this.map.loaded()) {
            return;
        }

        let LayersMap = currentLayers.reduce((a, b, index) => {
            a[b.layerId] = index;
            return a;
        }, {});

        for (let i = 0; i < previousLayers.length; i++) {
            let layer = previousLayers[i];

            if (!LayersMap.hasOwnProperty(layer.layerId)) {
                this.map.removeLayer(layer.layerId);
            }
        }
    }

    changeLayers(previousLayers, currentLayers) {
        let previousLayersMap = previousLayers.reduce((a, b, index) => {
            a[b.layerId] = index;
            return a;
        }, {});

        for (let i = 0; i < currentLayers.length; i++) {
            let layer = currentLayers[i];
            let previousLayer = previousLayers[previousLayersMap[layer.layerId]];

            if (layer.changed) {
                let layers = this.map.getStyle().layers.filter(x => x.source === layer.sourceId);
                this.map.removeLayer(layer.layerId);

                var vectorSource = {
                    id: layer.layerId,
                    type: layer.type,
                    source: layer.sourceId,
                    "source-layer": layer.sourceName,
                    paint: {}
                };

                if (layer.drawBefore !== null) {
                    this.map.addLayer(vectorSource, layer.drawBefore);
                } else {
                    this.map.addLayer(vectorSource);
                }

                let paint = this.props.mapState.paints.find(x => x.layerId == layer.layerId) || {properties:[]};
                let layout = this.props.mapState.layouts.find(x => x.layerId == layer.layerId)|| {properties:[]};

                this.changePaint([paint]);
                this.changeLayout([layout]);
            }
        }
    }

    changePaint(paints) {
        for (let k = 0; k < paints.length; k++) {
            let paint = paints[k];

            for (let i = 0; i < paint.properties.length; i++) {
                let paintProperty = paint.properties[i];

                this.map.setPaintProperty(paint.layerId, paintProperty.name, paintProperty.value);
            }
        }
    }

    addPaint(paints) {
        for (let k = 0; k < paints.length; k++) {
            let paint = paints[k];

            for (let i = 0; i < paint.properties.length; i++) {
                let paintProperty = paint.properties[i];

                this.map.setPaintProperty(paint.layerId, paintProperty.name, paintProperty.value);
            }
        }
    }

    changeLayout(layouts) {
        for (let k = 0; k < layouts.length; k++) {
            let layout = layouts[k];
            for (let i = 0; i < layout.properties.length; i++) {
                let layoutProperty = layout.properties[i];

                this.map.setLayoutProperty(layout.layerId, layoutProperty.name, layoutProperty.value);
            }
        }
    }

    jumpTo(options) {
        this.map.jumpTo(options);
    }

    fitBounds(bbox, options) {
        this.map.fitBounds(bbox, options);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mapState.layers.length < this.props.mapState.layers.length) {
            let layerID = "";
            if (this.props.mapState.layers.length < 3) {
                layerID = this.props.mapState.layers[0].layerId;
            } else {
                layerID = this.props.mapState.layers[this.props.mapState.layers.length - 2].layerId;
            }
            this.addLayers(layerID, this.props.mapState.layers.slice(prevProps.mapState.layers.length, this.props.mapState.layers.length));
        } else if (prevProps.mapState.layers.length > this.props.mapState.layers.length) {
            this.removeLayers(prevProps.mapState.layers, this.props.mapState.layers);
        } else if (JSON.stringify(prevProps.mapState.layers) !== JSON.stringify(this.props.mapState.layers)) {
            this.changeLayers(prevProps.mapState.layers, this.props.mapState.layers);
        }

        if (prevProps.mapState.paints.length < this.props.mapState.paints.length) {
            this.addPaint(this.props.mapState.paints.slice(prevProps.mapState.paints.length, this.props.mapState.paints.length));
        } else if (prevProps.mapState.paints !== this.props.mapState.paints) {
            this.changePaint(this.props.mapState.paints);
        }

        if (prevProps.mapState.layouts !== this.props.mapState.layouts) {
            this.changeLayout(this.props.mapState.layouts);
        }

        if (prevProps.mapState.jumpLocation !== this.props.mapState.jumpLocation) {
            this.jumpTo(this.props.mapState.jumpLocation);
        }

        if (prevProps.mapState.fitBounds !== this.props.mapState.fitBounds) {
            this.fitBounds(this.props.mapState.fitBounds.bbox, this.props.mapState.fitBounds.options);
        }
    }

    render() {
        const { viewport, mapStyle } = this.state;

        return <div id="map">{/* <InfoBox features={this.state.selectedFeatures} /> */}</div>;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        mapState: state.map,
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
