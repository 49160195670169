import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import AddIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from "@material-ui/core/Tooltip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import GeometryTypeIcon from "@material-ui/icons/Category";
import SortNameIcon from "@material-ui/icons/SortByAlpha";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";

import Dataset from "./components/dataset";

import CreateModal from "./createDatasetModal";

import * as datasetsActions from "../../../actions/datasets";
import * as mapActions from "../../../actions/map";
import * as tableViewActions from "../../../actions/tableView";
import { history } from "../../../store";
import { toastr } from "react-redux-toastr";

import colors from "../../../scss/variables.scss";

const styles = theme => ({
    title: {
        flexGrow: 1
    }
});

class DatasetsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: null,
            modelOpen: false,
            search: "",
            sorting: "name",
            sortingReverse: false,
            rowsPerPage: 10,
            page: 0
        };

        this.sortings = {
            name: sortingReverse => (sortingReverse ? this.sortByNameReverse : this.sortByName),
            schema: sortingReverse => (sortingReverse ? this.sortBySchema : this.sortBySchemaReverse),
            geometry: sortingReverse => (sortingReverse ? this.sortByGeometryTypeReverse : this.sortByGeometryType),
            modified: sortingReverse => (sortingReverse ? this.sortByModifiedReverse : this.sortByModified)
        };
    }

    componentDidMount() {
        this.props.getDatasets();
    }

    sortByName(dataset1, dataset2) {
        return dataset1.name.localeCompare(dataset2.name);
    }

    sortByNameReverse(dataset1, dataset2) {
        return dataset2.name.localeCompare(dataset1.name);
    }

    sortBySchema(dataset1, dataset2) {
        return dataset1.schemaName.localeCompare(dataset2.schemaName);
    }

    sortBySchemaReverse(dataset1, dataset2) {
        return dataset2.schemaName.localeCompare(dataset1.schemaName);
    }

    sortByGeometryType(dataset1, dataset2) {
        return dataset1.geometryType.localeCompare(dataset2.geometryType);
    }

    sortByGeometryTypeReverse(dataset1, dataset2) {
        return dataset2.geometryType.localeCompare(dataset1.geometryType);
    }

    sortByModified(dataset1, dataset2) {
        return new Date(dataset2.modifiedUtc) - new Date(dataset1.modifiedUtc);
    }

    sortByModifiedReverse(dataset1, dataset2) {
        return new Date(dataset1.modifiedUtc) - new Date(dataset2.modifiedUtc);
    }

    componentWillUnmount() {
        this.props.removeMapLayer({ layerId: this.state.selected });
    }

    datasetOnClick(item) {
        this.props.removeMapLayer({ layerId: this.state.selected }).then(res => {
            this.props.setTableViewDataset(item.id);
            let layerType = this.getLayerType(item.geometryType);
            this.props.addMapLayer({
                sourceId: item.id,
                layerId: item.id,
                sourceName: item.name,
                type: layerType,
                minZoom: item.minZoom,
                maxZoom: item.maxZoom
            });
            this.props.addMapPaint({
                layerId: item.id,
                properties: [
                    {
                        name: layerType + "-color",
                        value: colors.primaryColor
                    }
                ]
            });
            this.props.mapFitBounds([item.bounds.coordinates[0][0], item.bounds.coordinates[0][2]], {
                padding: { top: 45, bottom: 45, left: 45, right: 45 },
                animate: false
            });
        });

        this.setState({
            selected: item.id
        });
    }

    getLayerType(type) {
        switch (type) {
            case "POLYGON":
            case "MULTIPOLYGON":
                return "fill";
            case "POINT":
            case "MULTIPOINT":
                return "circle";
            case "LINESTRING":
            case "MULTILINESTRING":
                return "line";
        }
    }

    onEditClick = item => {
        history.push("/datasets/edit/" + item.id);
    };

    onClickDeleteDataset(item) {
        const toastrConfirmOptions = {
            onOk: () => this.deleteDataset(item),
            onCancel: () => console.log("CANCEL: clicked")
        };
        toastr.confirm("Delete dataset " + item.name, toastrConfirmOptions);
    }

    onClickAddDataset = () => {
        this.setState({
            modelOpen: true
        });
    };

    onSearch = e => {
        this.setState({
            search: e.target.value
        });
    };

    onModelClose = () => {
        this.setState({
            modelOpen: false
        });
    };

    deleteDataset(item) {
        this.props
            .deleteDataset(item.id)
            .then(res => {
                toastr.success("Success", "Dataset deleted");
            })
            .catch(err => {
                toastr.error("Error", err.message);
            });
    }

    onSetSort = sort => {
        this.setState({
            sorting: sort,
            sortingReverse: this.state.sorting === sort ? !this.state.sortingReverse : false
        });
    };

    onChangePage = (event, page) => {
        this.setState({
            page: page
        });
    };

    onChangeRowsPerPage = e => {
        this.setState({
            rowsPerPage: e.target.value
        });
    };

    render() {
        let { classes } = this.props;

        let sliceStart = this.state.page * this.state.rowsPerPage;
        let SliceEnd = this.state.page * this.state.rowsPerPage + this.state.rowsPerPage;

        let datasets = this.props.datasets.data
            .filter(x => x.name.toLowerCase().includes(this.state.search.toLowerCase()))
            .sort(this.sortings[this.state.sorting](this.state.sortingReverse))
            .slice(sliceStart, SliceEnd)
            .map((dataset, index) => {
                let selected = dataset.id === this.state.selected;

                return (
                    <Dataset
                        key={index}
                        dataset={dataset}
                        selected={selected}
                        OnClick={() => this.datasetOnClick(dataset)}
                        onDoubleClick={() => this.onEditClick(dataset)}
                        onEditClick={() => this.onEditClick(dataset)}
                        onDeleteClick={() => this.onClickDeleteDataset(dataset)}
                    />
                );
            });

        return (
            <div className="sidebar-container datasets-overview">
                <CreateModal open={this.state.modelOpen} handleClose={this.onModelClose} />
                <AppBar position="static">
                    <Toolbar className="toolbar">
                        <img src="/Logo.svg"></img>
                    </Toolbar>
                </AppBar>
                <div className="header">
                    <Typography variant="h6" className={classes.title}>
                        Datasets
                    </Typography>
                    <Button color="primary" variant="contained" onClick={this.onClickAddDataset}>
                        <AddIcon style={{marginRight:8,marginLeft:-8}} />
                        Add Dataset
                    </Button>
                  
                </div>
                {this.props.datasets.fetching && <LinearProgress className="no-margin-progress" />}
                {!this.props.datasets.fetching && (
                    <div className="content">
                        <div className="search">                      
                            <TextField
                                id="full-width"
                                inputProps={{ autoComplete: "off" }}
                                placeholder="Search datasets"
                                value={this.state.search}
                                onChange={this.onSearch}
                                fullWidth
                                margin="dense"
                            />
                        </div>
                        <div className="item-toolbar">
                            <Tooltip id="tooltip-top" title="Sort by geometry type">
                                <div className={this.state.sorting === "geometry" ? "button active geometry" : "button geometry"} onClick={() => this.onSetSort("geometry")}>
                                    <GeometryTypeIcon />
                                </div>
                            </Tooltip>
                            <Tooltip id="tooltip-top" title="Sort by schema">
                                <div className={this.state.sorting === "schema" ? "button active schema" : "button schema"} onClick={() => this.onSetSort("schema")}>
                                    Schema
                                </div>
                            </Tooltip>
                            <Tooltip id="tooltip-top" title="Sort by name">
                                <div className={this.state.sorting === "name" ? "button active name" : "button name"} onClick={() => this.onSetSort("name")}>
                                    Name
                                </div>
                            </Tooltip>
                            <Tooltip id="tooltip-top" title="Sort by modified">
                                <div className={this.state.sorting === "modified" ? "button active date" : "button date"} onClick={() => this.onSetSort("modified")}>
                                    Modified
                                </div>
                            </Tooltip>
                        </div>
                        <div className="item-container">
                            {/* <div className="schema">
                            <Typography variant="subtitle1" display="inline" className="schema-name">
                                user_1
                            </Typography>

                            <div className="datasets">{datasets}</div>
                        </div> */}
                            {datasets}
                        </div>
                        <div className="pagination">
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15]}
                                component="div"
                                count={this.props.datasets.data.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                backIconButtonProps={{
                                    "aria-label": "Previous Page"
                                }}
                                nextIconButtonProps={{
                                    "aria-label": "Next Page"
                                }}
                                onChangePage={this.onChangePage}
                                onChangeRowsPerPage={this.onChangeRowsPerPage}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        datasets: state.datasets
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getDatasets: () => dispatch(datasetsActions.getDatasetsAdmin()),
        selectDataset: dataset => dispatch(datasetsActions.selectDataset(dataset)),
        deleteDataset: id => dispatch(datasetsActions.deleteDataset(id)),
        addMapLayer: layer => dispatch(mapActions.addLayer(layer)),
        removeMapLayer: layer => dispatch(mapActions.removeLayerAsync(layer)),
        mapFitBounds: (bbox, options) => dispatch(mapActions.fitBounds(bbox, options)),
        addMapPaint: paint => dispatch(mapActions.addPaint(paint)),
        setTableViewDataset: id => dispatch(tableViewActions.setDataset(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatasetsView));
