import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import AddIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import StyleIcon from "@material-ui/icons/Brush";
import Tooltip from "@material-ui/core/Tooltip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import TextField from "@material-ui/core/TextField";
import CreateModal from "./createMapModal";
import Map from "./components/map";

import * as datasetsActions from "../../../actions/datasets";
import * as mapActions from "../../../actions/map";
import * as mapServiceActions from "../../../actions/mapService";
import { history } from "../../../store";
import { toastr } from "react-redux-toastr";
import { LinearProgress } from "@material-ui/core";

const styles = theme => ({
    title: {
        flexGrow: 1
    }
});

class MapsListView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: "",
            modelOpen: false,
            search: ""
        };
    }

    componentDidMount() {
        this.props.getDatasets();
    }

    datasetOnClick = map => {
        // this.props.selectDataset(item);
        // this.props.setLayer({
        //     sourceId: map.id,
        //     layerId: map.name,
        //     sourceName: 'house',
        //     type: 'fill',
        //     minZoom: 10,
        //     maxZoom: 16
        // });
        this.setState({
            selected: map.id
        });
        //history.push('/datasets/edit/' + item.id)
    };

    onEditClick = item => {
        history.push("/maps/edit/" + item.id);
    };

    onStyleClick = item => {
        history.push("/maps/style/" + item.id);
    };

    onDeleteMap = map => {
        const toastrConfirmOptions = {
            onOk: () => this.deleteMap(map),
            onCancel: () => console.log("CANCEL: clicked")
        };
        toastr.confirm("Delete map " + map.name, toastrConfirmOptions);
    };

    deleteMap = map => {
        this.props
            .deleteMap(map.id)
            .then(res => {
                toastr.success("Success", "Map deleted");
            })
            .catch(err => {
                toastr.error("Error", err.message);
            });
    };

    onClickAddDataset = () => {
        this.setState({
            modelOpen: true
        });
    };

    onSearch = e => {
        this.setState({
            search: e.target.value
        });
    };

    onModelClose = () => {
        this.setState({
            modelOpen: false
        });
    };

    onFileDragOver(e) {
        e.preventDefault();
    }

    onFileDrop = e => {
        e.preventDefault();
        this.props.uploadDataset(e.dataTransfer.files[0], { name: "house" }).then(res => {
            history.push("/maps/edit/" + res.result.id);
        });
    };

    onDeleteDataset = () => {};

    render() {
        let { classes } = this.props;

        let maps = this.props.mapsState.maps
            .filter(x => x.name.includes(this.state.search))
            .slice(0, 10)
            .map((map, index) => {
                let selected = map.id === this.state.selected;

                return (
                    <Map
                        map={map}
                        selected={selected}
                        OnClick={this.datasetOnClick}
                        onDoubleClick={this.onEditClick}
                        onEditClick={this.onEditClick}
                        onDeleteClick={this.onDeleteMap}
                        onStyleClick={this.onStyleClick}
                        key={index}
                    />
                );
            });

        return (
            <div className="sidebar-container maps-overview">
                <CreateModal open={this.state.modelOpen} handleClose={this.onModelClose} />
                <AppBar position="static">
                    <Toolbar className="toolbar">
                        <img src="/Logo.svg"></img>
                    </Toolbar>
                </AppBar>
                <div className="header">
                    <Typography variant="h6" className={classes.title}>
                        Maps
                    </Typography>
                    <Button color="primary" variant="contained" onClick={this.onClickAddDataset}>
                        <AddIcon style={{ marginRight: 8, marginLeft: -8 }} />
                        Add Map
                    </Button>
                </div>
                {this.props.mapsState.fetching && <LinearProgress className="no-margin-progress" />}
                <div className="content">
                    <div className="search">
             
                        <TextField
                            id="full-width"
                            inputProps={{ autoComplete: "off" }}
                            placeholder="Search maps"
                            value={this.state.search}
                            onChange={this.onSearch}
                            fullWidth
                            margin="dense"
                        />
                    </div>
                    <div className="item-container">{maps}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        mapsState: state.mapService,
        datasetsState: state.datasets
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getDatasets: () => dispatch(datasetsActions.getDatasets()),
        selectDataset: dataset => dispatch(datasetsActions.selectDataset(dataset)),
        deleteMap: mapId => dispatch(mapServiceActions.deleteMap(mapId)),
        setLayer: layer => dispatch(mapActions.addLayer(layer))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MapsListView));
