import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


function PropertyToolbar(props) {

    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose(fn) {
        setAnchorEl(null);
        if (fn !== undefined)
            fn()
    }


    return (
        <div className="toolbar">
            <div className="actions">
                <Tooltip title="More">
                    <div className="action" onClick={handleClick}>
                        <MoreIcon></MoreIcon>
                    </div>
                </Tooltip>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => handleClose()}
                >
                    {props.options.map((item, index) => {
                        return <MenuItem key={index} onClick={() => {handleClose(item.fn)}}>{item.title}</MenuItem>
                    })}
                </Menu>

            </div>
        </div>
    );
}

export default PropertyToolbar;