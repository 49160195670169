import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/Clear';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import ColorPopover from './colorPopover'

import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FilledInput from '@material-ui/core/FilledInput';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import CategoriseIcon from '@material-ui/icons/FormatListNumbered';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';

import * as datasetActions from '../../../../../actions/datasets'

import Toolbar from './propertyToolbar'

let colorsArray = [
    '#EF5350', '#EC407A', '#AB47BC', '#7E57C2', '#5C6BC0',
    '#42A5F5', '#29B6F6', '#26C6DA', '#26A69A', '#66BB6A',
    '#9CCC65', '#D4E157', '#FFEE58', '#FFCA28', '#FFA726',
    '#FF7043', '#8D6E63', '#BDBDBD', '#78909C', '#FFCDD2',
    '#F8BBD0', '#E1BEE7'
]

class MultiColorProperty extends Component {

    constructor(props) {
        super(props)

        let colors = {}
        for (let i = 2; i < props.value.length - 1; i += 2) {
            colors[props.value[i]] = props.value[i + 1]
        }

        this.state = {
            newColorName: '',
            selectedColumn: props.value[1][1],
            colors: {
                ...colors,
                Default: props.value[props.value.length - 1]
            },
            menuOptions: [
                {
                    title: 'Switch to single color',
                    fn: () => this.props.onPropertyTypeChanged('color', this.state.colors.Default)
                }
            ]
        }
    }

    getDisctinctColumnValues = () => {
        datasetActions.getDistinctColumnValues(this.props.datasetId, this.state.selectedColumn).then(res => {

            let colorMap = res.reduce((a, b, index) => {
                a[b] = colorsArray[index];
                return a;
            }, {})


            this.setState({
                colors: {
                    ...colorMap,
                    Default: this.props.value[this.props.value.length - 1]
                }
            })

            let colorKeys = Object.keys(this.state.colors)

            let newValue = this.buildColorArray(colorKeys, this.state.colors)

            this.props.onPropertyChanged(newValue)
        })


    }

    changePropertyName = (e) => {
        this.setState({
            selectedColumn: e.target.value,
            colors: {
                Default: this.state.colors.Default
            }
        })
    }

    changeNewColorName = (e) => {
        this.setState({
            newColorName: e.target.value
        })
    }

    addColor(name) {
        this.setState({
            newColorName: '',
            colors: {
                ...this.state.colors,
                [name]: 'darkcyan'
            }
        }, () => {
            let colorKeys = Object.keys(this.state.colors)

            let newValue = this.buildColorArray(colorKeys, this.state.colors)

            this.props.onPropertyChanged(newValue)
        })


    }

    deleteColor(name) {
        let colors = this.state.colors

        delete colors[name];

        this.setState({
            colors: colors
        }, () => {
            let colorKeys = Object.keys(this.state.colors)

            let newValue = this.buildColorArray(colorKeys, this.state.colors)

            this.props.onPropertyChanged(newValue)
        })
    }

    colorChanged(prop, value) {
        this.setState({
            colors: {
                ...this.state.colors,
                [prop]: value.hex
            }
        })

        let colorKeys = Object.keys(this.state.colors)

        let newValue = this.buildColorArray(colorKeys, this.state.colors)

        this.props.onPropertyChanged(newValue)
    }

    buildColorArray(keys, colors) {
        if (keys.length == 1) {
            return ['match', 'true','true', colors['Default'],'white']
        }

        let colorsArray = keys
            .filter(x => x !== 'Default')
            .reduce((a, b) => {
                a.push(b, colors[b]);
                return a
            }, [])

        return [
            'match',
            ['get', this.state.selectedColumn],
            ...colorsArray,
            colors['Default']
        ]
    }

    compareColors(a, b) {
        if (a.name.toLowerCase() === 'default') {
            return -1;
        }
        if (b.name.toLowerCase() === 'default') {
            return 1;
        }

        if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
        }
        return 0;
    }

    changeToSimpleColor = () => {
        this.props.onPropertyTypeChanged('color', this.state.colors.Default)
    }

    render() {
        let properties = Object.keys(this.state.colors)
            .map(x => { return { name: x, value: this.state.colors[x] } })
            .sort(this.compareColors)
            .map((item, index) => {
                return (
                    <div className="property-content">
                        {item.name !== 'Default' && <div className="delete-btn" onClick={() => this.deleteColor(item.name)}>
                            <DeleteIcon />
                        </div>}
                        <Typography variant='body2' className="name" >{item.name}</Typography>

                        <ColorPopover color={item.value} onColorChanged={(value) => this.colorChanged(item.name, value)} />
                    </div>
                )
            })

        let menuitems = this.props.columns.map((item, index) => {
            return <MenuItem value={item}>{item}</MenuItem>
        })

        return (
            <div className="property">
                <Toolbar options={this.state.menuOptions}></Toolbar>
                <div className="property-content">
                    <Typography variant='body1' className="name" >{this.props.title}</Typography>
                    <div>
                        <IconButton onClick={this.getDisctinctColumnValues} aria-label="Delete" className="mini-icon" size="small">
                            <CategoriseIcon fontSize="inherit" />
                        </IconButton>
                    </div>

                    <div className="select">
                        <FormControl fullWidth variant="filled" className="property-select" >
                            <InputLabel htmlFor="filled-age-simple">Column</InputLabel>
                            <Select
                                value={this.state.selectedColumn}
                                onChange={this.changePropertyName}

                                input={<FilledInput name="age" id="filled-age-simple" />}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {menuitems}
                            </Select>
                        </FormControl>
                    </div>


                </div>
                <div className="property-content">
                    <div className="text-input">
                        <TextField
                            id="filled-name"
                            label="Name"

                            value={this.state.newColorName}
                            onChange={this.changeNewColorName}
                            fullWidth
                            variant="filled"
                        />
                    </div>

                    <div className="add-btn" onClick={() => this.addColor(this.state.newColorName)}>
                        <AddIcon />
                    </div>
                </div>
                {properties}
            </div>
        );
    }

}

export default MultiColorProperty;