import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import DragDropIcon from "@material-ui/icons/SaveAlt";
import Button from "@material-ui/core/Button";

import * as ValidationUtils from "../../utils/validationUtils";

import { kml } from "@tmcw/togeojson";
import * as shapefile from "shapefile";
import { csv } from "csv2geojson";
import { Switch, FormControlLabel } from "@material-ui/core";

function InvalidColumnName(property) {
    return (
        <div className="file-error">
            <div className="title">Column naming error</div>
            <div className="text">
                A column cannot be named <div className="highlight">{property}</div>
            </div>
        </div>
    );
}

let geojsonData;

const styles = (theme) => ({
    root: {}
});

class FileUpload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            multiFileMode: false,
            dragging: false,
            file: undefined
        };
    }

    onFileDragOver = (e) => {
        e.preventDefault();
        this.setState({
            dragging: true
        });
    };

    onFileDragLeave = (e) => {
        e.preventDefault();
        this.setState({
            dragging: false
        });
    };

    onFileDrop = (e) => {
        e.preventDefault();

        this.setState({
            dragging: false
        });

        if (this.state.multiFileMode) {
            this.loadShapeFile(Object.values(e.dataTransfer.files));
        } else {
            this.loadFile(e.dataTransfer.files[0]);
        }
    };

    onFileChanged = (e) => {
        if (this.state.multiFileMode) {
            this.loadShapeFile(Object.values(e.target.files));
        } else {
            this.loadFile(e.target.files[0]);
        }
    };

    loadFile(file) {
        let filePromise = new Promise(function (resolve, reject) {
            let reader = new FileReader();
            reader.onload = function (theFile) {
                resolve(reader.result);
            };
            reader.readAsText(file);
        });

        filePromise.then((res) => {
            let data = this.convertFileToGeojson(file, res);
            if (this.validateGeoJsonProperties(data.features[0].properties)) {
                this.props.onDataChanged(data, file);
                this.setState({
                    file: file
                });
            }
        });
    }

    loadShapeFile(files) {
        let shapeFile = files.find((x) => x.name.includes(".shp"));

        let databaseFile = files.find((x) => x.name.includes(".dbf"));

        let projectFile = files.find((x) => x.name.includes(".prj"));

        let shpPromise = new Promise(function (resolve, reject) {
            let reader = new FileReader();
            reader.onload = function (theFile) {
                resolve(reader.result);
            };
            reader.readAsArrayBuffer(shapeFile);
        });

        let dbfPromise = new Promise(function (resolve, reject) {
            let reader = new FileReader();
            reader.onload = function (theFile) {
                resolve(reader.result);
            };
            reader.readAsArrayBuffer(databaseFile);
        });

        let prjPromise = new Promise(function (resolve, reject) {
            let reader = new FileReader();
            reader.onload = function (theFile) {
                resolve(reader.result);
            };
            reader.readAsText(projectFile);
        });

        Promise.all([shpPromise, dbfPromise, prjPromise]).then((res) => {
            let prjFile = res[2];

            shapefile
                .read(res[0], res[1])
                .then((result) => this.validateShapeFile(result, files))
                .catch((error) => console.error(error.stack));
        });
    }

    validateShapeFile(geojson, files) {
        if (this.validateGeoJsonProperties(geojson.features[0].properties)) {
            this.props.onFilesChanged(files);
            this.setState({
                file: files.find((x) => x.name.endsWith(".shp"))
            });
        }
    }

    convertFileToGeojson(file, fileContent) {
        switch (file.name.split(".").pop().toLowerCase()) {
            case "geojson":
                return JSON.parse(fileContent);
            case "kml":
                let parser = new DOMParser();
                let xmlDoc = parser.parseFromString(fileContent, "text/xml");
                return kml(xmlDoc);
        }
    }

    validateGeoJsonProperties(properties) {
        let errors = [];

        for (var propertyName in properties) {
            if (!ValidationUtils.validatePostgresIdentifier(propertyName)) {
                errors.push(InvalidColumnName(propertyName));
            }
        }

        this.props.onError(errors);

        return errors.length == 0;
    }

    onFileModeChanged = () => {
        this.setState({
            multiFileMode: !this.state.multiFileMode
        });
    };

    render() {
        let { classes } = this.props;

        return (
            <div className="file-upload">
                <FormControlLabel
                    className="multi-switch"
                    control={<Switch checked={this.state.multiFileMode} onChange={this.onFileModeChanged} name="test" />}
                    label="Shapefile "
                />

                <div
                    className={this.state.dragging ? "file-upload-box dragging" : "file-upload-box"}
                    onDrop={this.onFileDrop}
                    onDragOver={this.onFileDragOver}
                    onDragLeave={this.onFileDragLeave}
                >
                    <div style={{ marginBottom: 8 }}>
                        <DragDropIcon className="icon" />
                    </div>
                    <div>
                        <Typography variant="body1" className="text">
                            Drag and drop
                        </Typography>
                        {this.state.multiFileMode && (
                            <Typography variant="body1" className="text">
                                Shapefile (.shp,.dbf,.prj)
                            </Typography>
                        )}
                        {!this.state.multiFileMode && (
                            <Typography variant="body1" className="text">
                                .geojson, .csv, .kml
                            </Typography>
                        )}
                    </div>

                    <div className={classes.buttonContainer}>
                        <label htmlFor="raised-button-file">
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => {
                                    document.getElementById("file-button").click();
                                }}
                                className={"button"}
                            >
                                {this.state.multiFileMode ? "Choose files" : "Choose file"}
                            </Button>
                        </label>
                        <input
                            accept={this.state.multiFileMode ? ".shp,.dbf,.prj,.shx" : ".geojson,.kml"}
                            className={classes.input}
                            style={{ display: "none" }}
                            multiple={this.state.multiFileMode}
                            id="file-button"
                            type="file"
                            onChange={this.onFileChanged}
                        />
                    </div>
                </div>
                <div className="file-name-container">
                    <Typography variant="body1" className="file-name">
                        {this.state.file ? this.state.file.name : "No file selected"}
                    </Typography>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(FileUpload);
