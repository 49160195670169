import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import { datasetsReducer, datasetDetailsReducer } from "./datasets";
import { mapReducer } from "./map";
import { authReducer } from "./auth";
import { listViewReducer } from "./listView";
import { mapServiceReducer } from "./mapService";
import { usersReducer } from "./users";
import { groupsReducer } from "./groups";
import { tableViewReducer } from "./tableView";

// main reducers
const reducers = combineReducers({
    auth: authReducer,
    toastr: toastrReducer,
    map: mapReducer,
    listView: listViewReducer,
    datasets: datasetsReducer,
    mapService: mapServiceReducer,
    datasetDetails: datasetDetailsReducer,
    users: usersReducer,
    groups: groupsReducer,
    tableView:tableViewReducer
});

export default reducers;
