import * as types from "./actionTypes";
import axiosClient from "./apiClient";

export function addDatasetPermission(groupId, datasetId, permssionType) {
    return dispatch => {
        dispatch({ type: "none" });

        return axiosClient.post(`permission/${groupId}/datasets/${datasetId}`, { permission: permssionType }).then(
            res => {
                dispatch({ type: "none", result: res.data });
            },
            err => {
                dispatch({ type: "none", result: err });
                throw err;
            }
        );
    };
}

export function updateDatasetPermission(groupId, datasetId, permssionType) {
    return dispatch => {
        dispatch({ type: "none" });

        return axiosClient.put(`permission/${groupId}/datasets/${datasetId}`, { permission: permssionType }).then(
            res => {
                dispatch({ type: "none", result: res.data });
            },
            err => {
                dispatch({ type: "none", result: err });
                throw err;
            }
        );
    };
}

export function removeDatasetPermission(groupId, datasetId) {
    return dispatch => {
        dispatch({ type: "none" });

        return axiosClient.delete(`permission/${groupId}/datasets/${datasetId}`).then(
            res => {
                dispatch({ type: "none", result: res.data });
            },
            err => {
                dispatch({ type: "none", result: err });
                throw err;
            }
        );
    };
}

export function addMapPermission(groupId, mapId, permssionType) {
    return dispatch => {
        dispatch({ type: "none" });

        return axiosClient.post(`permission/${groupId}/maps/${mapId}`, { permission: permssionType }).then(
            res => {
                dispatch({ type: "none", result: res.data });
            },
            err => {
                dispatch({ type: "none", result: err });
                throw err;
            }
        );
    };
}

export function updateMapPermission(groupId, mapId, permssionType) {
    return dispatch => {
        dispatch({ type: "none" });

        return axiosClient.put(`permission/${groupId}/maps/${mapId}`, { permission: permssionType }).then(function(res) {
            dispatch(
                res => {
                    dispatch({ type: "none", result: res.data });
                },
                err => {
                    dispatch({ type: "none", result: err });
                    throw err;
                }
            );
        });
    };
}

export function removeMapPermission(groupId, mapId) {
    return dispatch => {
        dispatch({ type: "none" });

        return axiosClient.delete(`permission/${groupId}/maps/${mapId}`).then(function(res) {
            dispatch(
                res => {
                    dispatch({ type: "none", result: res.data });
                },
                err => {
                    dispatch({ type: "none", result: err });
                    throw err;
                }
            );
        });
    };
}
