import React, { useState } from "react";

import FillIcon from "@material-ui/icons/Category";
import LineIcon from "@material-ui/icons/Share";
import PointIcon from "@material-ui/icons/Adjust";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import DeleteIcon from "@material-ui/icons/Delete";

import LayerType from "./layerType";
import { Tooltip } from "@material-ui/core";

export default function Layer(props) {
    const [dragOver, setDragOver] = useState(false);

    function getPrimaryColor(layer) {
        switch (layer.type) {
            case "fill":
                return layer.properties.find(x => x.name == "fill-color").value;
            case "line":
                return layer.properties.find(x => x.name == "line-color").value;
            case "circle":
                return layer.properties.find(x => x.name == "circle-color").value;
            case "symbol":
                return layer.properties.find(x => x.name == "text-color").value;
        }
    }

    function onLayerDeleted(e, layerId) {
        e.stopPropagation();
        props.onLayerDeleted(layerId);
    }

    function onLayerVisibilityToggled(e, layerId, visable) {
        e.stopPropagation();
        props.onLayerVisibilityToggled(layerId, visable);
    }

    function onDragOver(event) {
        const index = event.dataTransfer.getData("text");

        event.preventDefault();
        setDragOver(true);
    }

    function onDragLeave(event) {
        setDragOver(false);
    }

    function onDrop(event) {
        const index = event.dataTransfer.getData("text");

        setDragOver(false);

        if (index == props.layer.index) {
            return;
        }

        props.onDrop(parseInt(index), props.layer.index);
    }

    function onDragStart(e) {
        e.dataTransfer.setData("text/plain", props.layer.index.toString());
    }

    function getLayerClassName() {
        let className = "layer";

        if (props.selected) {
            className += " selected";
        }
        if (dragOver) {
            className += " drag-highlight";
        }
        return className;
    }

    return (
        <div
            draggable={true}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDragStart={onDragStart}
            onDrop={onDrop}
            className={getLayerClassName()}
            onClick={() => props.onLayerSelected(props.layer.index)}
        >
            <LayerType type={props.layer.type} color={getPrimaryColor(props.layer)}></LayerType>
            <div className="name">{props.layer.name}</div>
            <div className="actions">
                <Tooltip title={props.layer.visable ? "Hide" : "Show"}>
                    <div>
                        {props.layer.visable ? (
                            <VisibilityOffIcon className="action" onClick={e => onLayerVisibilityToggled(e, props.layer.layerId, props.layer.visable)} />
                        ) : (
                            <VisibilityIcon className="action" onClick={e => onLayerVisibilityToggled(e, props.layer.layerId, props.layer.visable)} />
                        )}
                    </div>
                </Tooltip>

                <Tooltip title="Delete">
                    <DeleteIcon className="action" onClick={e => onLayerDeleted(e, props.layer.layerId)} />
                </Tooltip>
            </div>
        </div>
    );
}
