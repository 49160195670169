import * as types from '../actions/actionTypes'

const listViewInit = {
    fetching: false,
    features:[]
};

export function listViewReducer(state = listViewInit, action) {
    switch (action.type) {
        case types.FETCH_FEATURES:
            return {
                ...state,
                fetching:true,
            }
        case types.FETCH_FEATURES_COMPLETE:
            return {
                ...state,
                fetching:false,
                features:[...action.result]
            }
        default:
            return state;
    }
}
