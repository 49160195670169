import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import FillIcon from "@material-ui/icons/TabUnselected";
import LineIcon from "@material-ui/icons/Timeline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import Layer from "./components/layer";

import Style from "./components/style";
import styleConfig from "./components/styleConfig";

import * as mapActions from "../../../actions/map";
import * as datasetsActions from "../../../actions/datasets";
import * as mapServiceActions from "../../../actions/mapService";
import { toastr } from "react-redux-toastr";
import { v4 as uuidv4 } from "uuid";

const styles = theme => ({
    title: {
        flexGrow: 1,
        color: "white"
    },
    whiteIcon: {
        color: theme.palette.text.main
    }
});

let transformDict = {
    color: (x) => x,
    "multi-color": (x) => x,
    number: (x) => parseInt(x),
    numberArray: (x) => x,
    float: (x) => parseFloat(x),
    text: (x) => x,
    select: (x) => x,
    boolean: (x) => x
};

function getNewLayerId() {
    return uuidv4();
}

class EditStyleView extends Component {
    constructor(props) {
        super(props);

        this.layerId = 0;
        this.styleConfig = styleConfig;

        this.state = {
            columns: {},
            datasets: [],
            layers: [],
            style: { name: "" },
            selectedLayer: 0,
            mapId: props.match.params.mapId,
            styleId: ""
        };
    }

    componentDidMount() {
        let details = this.props.getMap(this.state.mapId);
        let styles = this.props.getMapStyles(this.state.mapId);

        Promise.all([details, styles]).then(actions => {
            let detailsAction = actions[0];
            let styleAction = actions[1];

            this.setDetails(detailsAction, styleAction);
        });
    }

    componentWillUnmount() {
        this.props.removeAllMapLayers();
    }

    setDetails(details, styleAction) {
        let firstStyle = styleAction.result[0];

        let layers = [];

        for (let layerIndex = 0; layerIndex < firstStyle.style.length; layerIndex++) {
            let style = firstStyle.style[layerIndex];
            let properties = JSON.parse(JSON.stringify(this.styleConfig[style.type]));
            let dataset = details.result.datasets.find(dataset => dataset.name == style.sourceName);
            if (!dataset) {        
                continue;
            }
            let newLayerId = getNewLayerId();

            this.props.addMapLayer({
                sourceId: details.result.id,
                layerId: newLayerId,
                sourceName: dataset.name,
                type: style.type,
                minZoom: details.result.minZoom,
                maxZoom: details.result.maxZoom,
                index: layerIndex
            });
            this.initializeStyle(style, newLayerId);

            let styleProperties = [...style.paint, ...style.layout];

            for (let i = 0; i < properties.length; i++) {
                let property = properties[i];
                let styleProperty = styleProperties.find(x => x.name === property.name);
                if (styleProperty) {
                    property.value = styleProperty.value;
                    property.propertyType = styleProperty.propertyType;
                    property.expressionType = styleProperty.expressionType || 'none';
                }
            }

            layers.push({
                name: style.name,
                sourceId: this.props.match.params.mapId,
                layerId: newLayerId,
                dataset: dataset,
                minZoom: style.minZoom,
                maxZoom: style.maxZoom,
                type: style.type,
                properties: properties,
                index: layerIndex,
                visable: true
            });
        }

        //this.props.addMapLayers(layers);

        this.props.mapFitBounds([details.result.bounds.coordinates[0][0], details.result.bounds.coordinates[0][2]], {
            padding: { top: 45, bottom: 45, left: 45, right: 45 },
            animate: false
        });

        this.setState({
            columns: [""],
            map:details.result,
            datasets: details.result.datasets,
            selectedLayer: 0,
            style: firstStyle,
            layers: layers,
            styleId: firstStyle.id
        });
    }

    initializeStyle(style, layerId) {
        let paint = {
            layerId: layerId,
            properties: style.paint
        };

        let layout = {
            layerId: layerId,
            properties: style.layout
        };

        this.props.addMapPaint(paint);
        this.props.addMapLayout(layout);
    }

    initializeNewStyle(properties, layerId) {
        let paintProperties = properties.filter(x => x.type === "paint");

        let paint = {
            layerId: layerId,
            properties: paintProperties
        };

        let layoutProperties = properties.filter(x => x.type === "layout");

        let layout = {
            layerId: layerId,
            properties: layoutProperties
        };

        this.props.addMapPaint(paint);
        this.props.addMapLayout(layout);
    }

    setSelectedLayer = index => {
        this.setState({
            selectedLayer: index
        });
    };

    deleteLayer = layerId => {
        let newLayers = this.state.layers
            .filter(x => x.layerId !== layerId)
            .map((item, index) => {
                return { ...item, index: index };
            });

        this.setState({
            layers: newLayers,
            selectedLayer: newLayers.length - 1
        });
        this.props.removeMapLayer({ layerId });
    };

    changeLayerDataset(layer, dataset) {
        let properties = JSON.parse(JSON.stringify(this.styleConfig[layer.type]));

        this.setState({
            layers: this.state.layers.map(x => {
                if (x.layerId === layer.layerId) {
                    return {
                        ...x,
                        dataset: dataset,
                        name: dataset.name
                    };
                }
                return x;
            })
        });
        this.props.updateMapLayer({
            sourceId: layer.sourceId,
            layerId: layer.layerId,
            sourceName: dataset.name,
            type: layer.type,
            minZoom: dataset.minZoom,
            maxZoom: dataset.maxZoom
        });

        this.initializeNewStyle(properties, layer.layerId);
    }

    changeLayerName(layer, name) {
        this.setState({
            layers: this.state.layers.map(x => {
                if (x.layerId === layer.layerId) {
                    return {
                        ...x,
                        name: name
                    };
                }
                return x;
            })
        });
    }

    onPropertiesChanged = (properties, layer) => {
        this.setState({
            layers: this.state.layers.map(x => {
                if (x.layerId === layer.layerId) {
                    return {
                        ...x,
                        properties: properties
                    };
                }
                return x;
            })
        });
    };

    onStyleTypeChanged = (type, layer) => {
        let properties = JSON.parse(JSON.stringify(this.styleConfig[type]));

        this.props.updateMapLayer({
            sourceId: layer.sourceId,
            layerId: layer.layerId,
            sourceName: layer.dataset.name,
            type: type,
            minZoom: layer.dataset.minZoom,
            maxZoom: layer.dataset.maxZoom,
            drawBefore: layer.index === 0 ? null : this.state.layers[layer.index - 1].layerId
        });

        this.initializeNewStyle(properties, layer.layerId);

        this.setState({
            layers: this.state.layers.map(x => {
                if (x.layerId !== layer.layerId) {
                    return x;
                }

                return {
                    ...x,
                    properties: properties,
                    type: type
                };
            })
        });
    };

    onLayerDrop = (droppedLayerIndex, hitLayerIndex) => {
        let layers = [...this.state.layers];
        let drawBefore = "";

        //Moving layer down
        if (droppedLayerIndex < hitLayerIndex) {
            drawBefore = layers[hitLayerIndex-1].layerId;
            for (let i = 0; i < layers.length; i++) {
                let layer = layers[i];

                if (layer.index < hitLayerIndex && layer.index > droppedLayerIndex) {
                    layer.index--;
                }
            }
            layers[droppedLayerIndex].index = hitLayerIndex - 1;
        }
        //Moving layer up
        else {
            if (hitLayerIndex === 0) {
                drawBefore = null;
            } else {
                drawBefore = layers[hitLayerIndex-1].layerId;
            }

            for (let i = 0; i < layers.length; i++) {
                let layer = layers[i];

                if (layer.index >= hitLayerIndex && layer.index <= droppedLayerIndex) {
                    layer.index++;
                }
            }
            layers[droppedLayerIndex].index = hitLayerIndex;
        }

        let layer = layers[droppedLayerIndex];

        layers.sort((a, b) => a.index - b.index);
        // layers.splice(dropLayerId,1);

        // layers.splice(layerId,0,this.state.layers[dropLayerId]);
        this.props.swapMapLayer({
            sourceId: layer.sourceId,
            layerId: layer.layerId,
            sourceName: layer.dataset.name,
            type: layer.type,
            drawBefore: drawBefore,
            minZoom: layer.dataset.minZoom,
            maxZoom: layer.dataset.maxZoom
        });

        console.log(layers);
        this.setState({
            layers: layers
        });
    };

    saveStyle = () => {
        let styles = this.state.layers.map((item, index) => {
            let transformedProperties = item.properties.map(prop => {
                return {
                    name: prop.name,
                    title: prop.title,
                    propertyType: prop.propertyType,
                    expressionType:prop.expressionType,
                    type: prop.type,
                    index: prop.index,
                    value: prop.value
                };
            });

            return {
                name: item.name,
                type: item.type,
                paint: transformedProperties.filter(x => x.type === "paint"),
                layout: transformedProperties.filter(x => x.type === "layout"),
                minZoom:item.minZoom,
                maxZoom:item.maxZoom,
                sourceName: item.dataset.name
            };
        });

        let updatedStyle = {
            name: "Default style",
            style: JSON.stringify(styles)
        };

        this.props.updateMapStyle(this.state.mapId, this.state.styleId, updatedStyle).then(res => {
            toastr.success("Sucess", "Style saved");
        });
    };

    addLayer = () => {
        let newLayerType = "circle";
        let properties = styleConfig[newLayerType];
        let newLayerId = getNewLayerId();
        let dataset = this.state.datasets[0];

        let newLayer = {
            name: dataset.name,
            sourceId: this.state.mapId,
            layerId: newLayerId,
            dataset: dataset,
            minZoom: this.state.map.minZoom,
            maxZoom: 24,
            type: newLayerType,
            properties: properties,
            index: this.state.layers.length,
            visable: true
        };

        let newLayers = [...this.state.layers, newLayer];

        this.props.addMapLayer({
            sourceId: this.state.mapId,
            layerId: newLayerId,
            sourceName: dataset.name,
            type: newLayerType,
            minZoom: dataset.minZoom,
            maxZoom: dataset.maxZoom,
            index: this.state.layers.length
        });

        this.initializeNewStyle(properties, newLayerId);

        this.setState({
            layers: newLayers,
            selectedLayer: newLayers.length - 1
        });
    };

    onLayerVisibilityToggled = (layerId, visable) => {
        this.setState({
            layers: this.state.layers.map(x => {
                if (x.layerId !== layerId) {
                    return x;
                }

                return {
                    ...x,
                    visable: !visable
                };
            })
        });

        this.props.changeMapLayout({
            layerId: layerId,
            properties: [
                {
                    name: "visibility",
                    value: !visable ? "visible" : "none"
                }
            ]
        });
    };

    onShowAllLayers = () => {
        this.setState({
            layers: this.state.layers.map(x => {
                this.props.changeMapLayout({
                    layerId: x.layerId,
                    properties: [
                        {
                            name: "visibility",
                            value: "visible"
                        }
                    ]
                });

                return {
                    ...x,
                    visable: true
                };
            })
        });
    };

    onHideAllLayers = () => {
        this.setState({
            layers: this.state.layers.map(x => {
                this.props.changeMapLayout({
                    layerId: x.layerId,
                    properties: [
                        {
                            name: "visibility",
                            value: "none"
                        }
                    ]
                });

                return {
                    ...x,
                    visable: false
                };
            })
        });
    };

    onZoomSliderChange = (layer, zoom) => {
        this.setState({
            layers: this.state.layers.map(x => {
                if (x.layerId !== layer.layerId) {
                    return x;
                }
                return {
                    ...x,
                    minZoom: zoom[0],
                    maxZoom: zoom[1]
                };
            })
        });
    };

    render() {
        let { classes } = this.props;

        let selectedLayer = this.state.layers[this.state.selectedLayer];

        let style = selectedLayer ? (
            <Style
                columns={[""]}
                map={this.state.map}
                datasets={this.state.datasets}
                layer={selectedLayer}
                onPropertiesChanged={properties => this.onPropertiesChanged(properties, selectedLayer)}
                onPropertyExpressionTypeChanged={type => this.onStyleTypeChanged(type, selectedLayer)}
                onTypeChanged={type => this.onStyleTypeChanged(type, selectedLayer)}
                onChangeDataset={dataset => this.changeLayerDataset(selectedLayer, dataset)}
                onNameChanged={name => this.changeLayerName(selectedLayer, name)}
                onZoomSliderChange={zoom => this.onZoomSliderChange(selectedLayer, zoom)}
            />
        ) : null;

        let layers = this.state.layers.map((layer, index) => {
            let selected = layer.index === this.state.selectedLayer;
            return (
                <Layer
                    key={index}
                    layer={layer}
                    selected={selected}
                    onLayerSelected={this.setSelectedLayer}
                    onLayerVisibilityToggled={this.onLayerVisibilityToggled}
                    onLayerDeleted={this.deleteLayer}
                    onDrop={this.onLayerDrop}
                />
            );
        });

        return (
            <div className="sidebar-container edit-style-view">
                <AppBar position="static">
                    <Toolbar className="toolbar">
                        <img src="/Logo.svg"></img>
                    </Toolbar>
                </AppBar>
                <div className="header">
                    <Typography variant="h6" className={classes.title}>
                        Style - {this.state.style.name}
                    </Typography>
                    <Tooltip title="Save">
                        <IconButton edge="start" className={classes.whiteIcon} onClick={this.saveStyle} color="inherit" aria-label="menu">
                            <SaveIcon />
                        </IconButton>
                    </Tooltip>
                </div>               
                <div className="content">
                    <div className="layers-container">
                        <Typography variant="h6">Layers</Typography>
                        <div className="list-actions">
                            <Tooltip title="Show All">
                                <VisibilityIcon className="action" onClick={this.onShowAllLayers} />
                            </Tooltip>

                            <Tooltip title="Hide All">
                                <VisibilityOffIcon className="action" onClick={this.onHideAllLayers} />
                            </Tooltip>
                        </div>
                        <div className="layers">
                            {layers}
                            <div className="add-button" onClick={this.addLayer}>
                                <AddIcon className="icon" />
                                <div>Add</div>
                            </div>
                        </div>
                    </div>
                    <div className="style-container">{style}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getMap: mapId => dispatch(mapServiceActions.getMap(mapId)),
        getMapStyles: mapId => dispatch(mapServiceActions.getMapStyles(mapId)),
        updateMapStyle: (mapId, styleId, style) => dispatch(mapServiceActions.updateMapStyle(mapId, styleId, style)),
        addMapLayer: layer => dispatch(mapActions.addLayer(layer)),
        addMapLayers: layers => dispatch(mapActions.addLayers(layers)),
        removeMapLayer: layer => dispatch(mapActions.removeLayerAsync(layer)),
        removeAllMapLayers: () => dispatch(mapActions.removeAllLayers()),
        updateMapLayer: layer => dispatch(mapActions.updateLayer(layer)),
        swapMapLayer: layer => dispatch(mapActions.swapLayer(layer)),
        addMapPaint: paint => dispatch(mapActions.addPaint(paint)),
        addMapLayout: layout => dispatch(mapActions.addLayout(layout)),
        changeMapPaint: paint => dispatch(mapActions.updatePaint(paint)),
        changeMapLayout: layout => dispatch(mapActions.updateLayout(layout)),
        mapFitBounds: (bounds, options) => dispatch(mapActions.fitBounds(bounds, options))
        // changeMapPaint: (paint) => dispatch(mapActions.setPaint(paint)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditStyleView));
