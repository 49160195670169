export const LOCATION_CHANGE = "LOCATION_CHANGE";

export const AUTHENTICATING = "AUTHENTICATING";
export const AUTHENTICATED = "AUTHENTICATED";
export const AUTHENTICATING_FAILED = "AUTHENTICATING_FAILED";

export const MAP_DRAWING_TOGGLED = "MAP_DRAWING_TOGGLED";
export const MAP_UPDATE_LAYER = "MAP_UPDATE_LAYER";
export const MAP_ADD_LAYER = "MAP_ADD_LAYER";
export const MAP_ADD_LAYERS = "MAP_ADD_LAYERS";
export const MAP_REMOVE_LAYER = "MAP_REMOVE_LAYER";
export const MAP_REMOVE_ALL_LAYERS = "MAP_REMOVE_ALL_LAYERS"
export const MAP_UPDATE_PAINT = "MAP_UPDATE_PAINT";
export const MAP_SWAP_LAYER = "MAP_SWAP_LAYER";
export const MAP_ADD_PAINT = "MAP_ADD_PAINT";
export const MAP_UPDATE_LAYOUT = "MAP_UPDATE_LAYOUT";
export const MAP_ADD_LAYOUT = "MAP_ADD_LAYOUT";
export const MAP_SET_LAYER_TYPE = "MAP_SET_LAYER_TYPE";
export const MAP_JUMP_TO = "MAP_JUMP_TO";
export const MAP_FIT_BOUNDS = "MAP_FIT_BOUNDS";

export const FETCH_MAP = "FETCH_MAP";
export const FETCH_MAP_COMPLETE = "FETCH_MAP_COMPLETE";
export const UPDATE_MAP = "UPDATE_MAP";
export const UPDATE_MAP_COMPLETE = "UPDATE_MAP_COMPLETE";
export const FETCH_MAP_STYLE = "FETCH_MAP_STYLE";
export const FETCH_MAP_STYLE_COMPLETE = "FETCH_MAP_STYLE_COMPLETE";
export const UPDATE_MAP_STYLE = "UPDATE_MAP_STYLE";
export const UPDATE_MAP_STYLE_COMPLETE = "UPDATE_MAP_STYLE_COMPLETE";

export const FETCH_MAPS = "FETCH_MAPS";
export const FETCH_MAPS_COMPLETE = "FETCH_MAPS_COMPLETE";
export const CREATE_MAP = "CREATE_MAP";
export const CREATE_MAP_COMPLETE = "CREATE_MAP_COMPLETE";
export const DELETE_MAP = "DELETE_MAP";
export const DELETE_MAP_COMPLETE = "DELETE_MAP_COMPLETE";
export const GENERATE_MAP_CACHE = "GENERATE_MAP_CACHE";
export const GENERATE_MAP_CACHE_COMPLETE = "GENERATE_MAP_CACHE_COMPLETE";

export const FETCH_DATASETS = "FETCH_DATASETS";
export const FETCH_DATASETS_COMPLETE = "FETCH_DATASETS_COMPLETE";

export const SELECT_DATASET = "SELECT_DATASET";

export const FETCH_DATASET_DETAILS = "FETCH_DATASET_DETAILS";
export const FETCH_DATASET_DETAILS_COMPLETE = "FETCH_DATASET_DETAILS_COMPLETE";

export const FETCH_DATASET_STYLE = "FETCH_DATASET_STYLE";
export const FETCH_DATASET_STYLE_COMPLETE = "FETCH_DATASET_STYLE_COMPLETE";

export const ADD_DATASET_STYLE = "ADD_DATASET_STYLE";
export const ADD_DATASET_STYLE_COMPLETE = "ADD_DATASET_STYLE_COMPLETE";

export const FETCH_GROUPS = "FETCH_GROUPS";
export const FETCH_GROUPS_COMPLETE = "FETCH_GROUPS_COMPLETE";
export const FETCH_GROUP = "FETCH_GROUP";
export const FETCH_GROUP_COMPLETE = "FETCH_GROUP_COMPLETE";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const UPDATE_GROUP_COMPLETE = "UPDATE_GROUP_COMPLETE";
export const CREATE_GROUP = "CREATE_GROUP";
export const CREATE_GROUP_COMPLETE = "CREATE_GROUP_COMPLETE";
export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_COMPLETE = "DELETE_GROUP_COMPLETE";
export const GROUP_ACTION_FAILED = "GROUP_ACTION_FAILED";

export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_COMPLETE = "FETCH_USERS_COMPLETE";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_COMPLETE = "FETCH_USER_COMPLETE";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_COMPLETE = "UPDATE_USER_COMPLETE";
export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_COMPLETE = "CREATE_USER_COMPLETE";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_COMPLETE = "DELETE_USER_COMPLETE";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const UPDATE_USER_PASSWORD_COMPLETE = "UPDATE_USER_PASSWORD_COMPLETE";
export const USER_ACTION_FAILED = "USER_ACTION_FAILED";

export const FETCH_DATASET_GEOJSON = "FETCH_DATASET_GEOJSON";
export const FETCH_DATASET_GEOJSON_COMPLETE = "FETCH_DATASET_GEOJSON_COMPLETE";

export const TABLE_VIEW_SET_DATASET = "TABLE_VIEW_SET_DATASET";

export const FETCH_TILE_COUNT = "FETCH_TILE_COUNT";
export const FETCH_TILE_COUNT_COMPLETE = "FETCH_TILE_COUNT_COMPLETE";

export const CREATE_DATASET = "CREATE_DATASET";
export const CREATE_DATASET_COMPLETE = "CREATE_DATASET_COMPLETE";

export const UPDATE_DATASET = "UPDATE_DATASET";
export const UPDATE_DATASET_COMPLETE = "UPDATE_DATASET_COMPLETE";

export const DELETE_DATASET = "DELETE_DATASET";
export const DELETE_DATASET_COMPLETE = "DELETE_DATASET_COMPLETE";

export const CREATE_CACHE = "CREATE_CACHE";
export const CREATE_CACHE_COMPLETE = "CREATE_CACHE_COMPLETE";

export const FETCH_FEATURES = "FETCH_FEATURES";
export const FETCH_FEATURES_COMPLETE = "FETCH_FEATURES_COMPLETE";

export const FETCH_SCHEMA_NAMES = "FETCH_SCHEMA_NAMES";
export const FETCH_SCHEMA_NAMES_COMPLETE = "FETCH_SCHEMA_NAMES_COMPLETE";
