import * as types from "../actions/actionTypes";

const usersInit = {
    fetching: false,
    users: []
};

export function usersReducer(state = usersInit, action) {
    switch (action.type) {
        case types.FETCH_USERS:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_USERS_COMPLETE:
            return {
                ...state,
                fetching: false,
                users: action.result
            };
        case types.CREATE_USER:
            return {
                ...state,
                fetching: true
            };
        case types.CREATE_USER_COMPLETE:
            return {
                ...state,
                fetching: false,
                users: [...state.users, action.result]
            };
        case types.UPDATE_USER:
            return {
                ...state,
                fetching: true
            };
        case types.UPDATE_USER_COMPLETE:
            return {
                ...state,
                fetching: false,
                users: updateArray(state.users, action.result)
            };
        case types.UPDATE_USER_PASSWORD:
            return {
                ...state,
                fetching: true
            };
        case types.UPDATE_USER_PASSWORD_COMPLETE:
            return {
                ...state,
                fetching: false
            };
        case types.DELETE_USER:
            return {
                ...state,
                fetching: true
            };
        case types.DELETE_USER_COMPLETE:
            return {
                ...state,
                fetching: false,
                users: removeFromArray(state.users, action.result)
            };
        case types.USER_ACTION_FAILED:
            return {
                ...state,
                fetching: false
            };
        default:
            return state;
    }
}

function removeFromArray(array, item) {
    return array.filter(x => item.id !== x.id);
}

function updateArray(array, item) {
    return array.map(x => {
        if (item.id === x.id) {
            return item;
        }
        return x;
    });
}
