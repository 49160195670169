import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "../../../store";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import LinkIcon from "@material-ui/icons/LinkOff";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import * as mapServiceActions from "../../../actions/mapService";
import * as datasetServiceActions from "../../../actions/datasets";

import { toastr } from "react-redux-toastr";

const styles = (theme) => ({
    title: {
        flexGrow: 1
    }
});

class MapEditView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            map: { datasets: [] },
            datasets: [],
            fetching: true,
            datasetDialogOpen: false,
            newDataset: "",
            changes: false
        };
    }

    componentDidMount() {
        this.props.getMap(this.props.match.params.mapId).then((mapResult) => {
            let map = mapResult.result;
            this.setState({
                map: map,
                minZoom: map.minZoom,
                maxZoom: map.maxZoom
            });
            let addedDatasets = map.datasets.reduce((a, b) => {
                a[b.id] = b;
                return a;
            }, {});

            let minZoom = 0;
            let maxZoom = 24;

            for (let i = 0; i < map.datasets.length; i++) {
                let dataset = map.datasets[i];
                if (dataset.minZoom > minZoom) {
                    minZoom = dataset.minZoom;
                }
                if (dataset.maxZoom < maxZoom) {
                    maxZoom = dataset.maxZoom;
                }
            }

            this.props.getDatasets().then((datasetResult) => {
                let datasets = datasetResult.result;
                let unusedDatasets = [];

                for (let i = 0; i < datasets.length; i++) {
                    let dataset = datasets[i];
                    if (!addedDatasets[dataset.id]) {
                        unusedDatasets.push(dataset);
                    }
                }

                let newDatasetValue = unusedDatasets.length > 0 ? unusedDatasets[0].id : "";

                this.setState({
                    datasets: unusedDatasets,
                    newDataset: newDatasetValue,
                    fetching: false,
                   
                });
            });
        });
    }

    changeMapName = (e) => {
        this.setState({
            map: { ...this.state.map, name: e.target.value },
            changes: true
        });
    };

    generateCache = () => {
        if (this.state.map.datasets.some((x) => !x.cached)) {
            toastr.error(`Not all datasets are cached`, `Error`);
            return;
        }

        this.setState({
            fetching: true
        });
        this.props.generateCache(this.props.match.params.mapId).then(
            (res) => {
                toastr.success(`Cache generated for ${this.state.map.name} `, `Success`);

                this.setState({
                    map: {
                        ...this.state.map,
                        cached: true
                    },
                    fetching: false
                });
            },
            (err) => {}
        );
    };

    onAddDatasetClick = () => {
        this.setState({
            datasetDialogOpen: true
        });
    };

    onAddDataset = () => {
        let newDataset = this.props.datasetsState.data.find((x) => x.id === this.state.newDataset);

        this.props
            .addDatasetToMap(this.state.map.id, newDataset.id)
            .then((res) => {
                toastr.success("Success", "Dataset added");
                this.setState({
                    map: {
                        ...this.state.map,
                        datasets: [...this.state.map.datasets, newDataset],
                        cached:false
                    },
                    datasetDialogOpen: false,
                    minZoom: newDataset.minZoom > this.state.minZoom ? newDataset.minZoom : this.state.minZoom,
                    maxZoom: newDataset.maxZoom < this.state.maxZoom ? newDataset.maxZoom : this.state.maxZoom
                });
            })
            .catch((err) => {
                toastr.error("Error", "An error occured please retry");
            });
    };

    onRemoveDataset = (dataset) => {
        this.props
            .removeDatasetFromMap(this.state.map.id, dataset.id)
            .then((res) => {
                toastr.success("Success", "Dataset removed");

                let newDatasets = this.state.map.datasets.filter((x) => x.id !== dataset.id);

                let minZoom = 0;
                let maxZoom = 24;

                for (let i = 0; i < newDatasets.length; i++) {
                    let dataset = newDatasets[i];
                    if (dataset.minZoom > minZoom) {
                        minZoom = dataset.minZoom;
                    }
                    if (dataset.maxZoom < maxZoom) {
                        maxZoom = dataset.maxZoom;
                    }
                }

                this.setState({
                    map: {...this.state.map, datasets: newDatasets,cached:false },
                    datasets: [...this.state.datasets, dataset],
                    minZoom: minZoom,
                    maxZoom: maxZoom
                });
            })
            .catch((err) => {
                toastr.error("Error", "An error occured please retry");
            });
    };

    onNewDatasetChange = (e) => {
        this.setState({
            newDataset: e.target.value
        });
    };

    onDatasetDialogClose = () => {
        this.setState({
            datasetDialogOpen: false
        });
    };

    onSaveChanges = () => {
        this.props.updateMap(this.state.map.id, this.state.map).then((res) => {
            this.setState({
                changes: false
            });
        });
    };

    render() {
        let { classes } = this.props;

        let datasets = this.state.map.datasets.map((dataset, index) => {
            return (
                <div className="dataset" key={index}>
                    <Tooltip title="Remove">
                        <LinkIcon onClick={() => this.onRemoveDataset(dataset)} className="icon" />
                    </Tooltip>
                    <Typography variant="title2" className="name">
                        {dataset.name}
                    </Typography>
                    <div className="cached-container">
                        <Typography variant="caption">{dataset.cached ? "cached" : "uncached"}</Typography>
                        <Typography variant="caption">
                            {dataset.minZoom} - {dataset.maxZoom}
                        </Typography>
                    </div>
                </div>
            );
        });

        let menuitems = this.state.datasets.map((item, index) => {
            return <MenuItem value={item.id}>{item.name}</MenuItem>;
        });

        return (
            <div className="sidebar-container map-edit-view">
                <AppBar position="static">
                    <Toolbar className="toolbar">
                        <img src="/Logo.svg"></img>
                    </Toolbar>
                </AppBar>
                <div className="header">
                    <Typography variant="h6" className={classes.title}>
                        Maps
                    </Typography>
                    <Tooltip title="generate cache" id="tooltip-top">
                        <div onClick={this.generateCache} className={this.state.map.cached ? "cache-status-container" : "cache-status-container uncached"}>
                            <Typography variant="title2">{this.state.map.cached ? "cached" : "uncached"}</Typography>
                        </div>
                    </Tooltip>
                </div>
             
                {this.state.fetching && <LinearProgress className="no-margin-progress" />}
                <div className="container">
                    <div className="section">
                        <FormLabel component="legend">Map name</FormLabel>
                        <div className="text-container">
                            <TextField
                                id="full-width"
                                variant="outlined"
                                placeholder="Name of the map"
                                value={this.state.map.name}
                                onChange={this.changeMapName}
                                fullWidth
                                margin="dense"
                            />
                        </div>
                    </div>

                    <div className="section">
                        <div>
                            <Typography variant="subtitle1">Map id</Typography>
                        </div>

                        <div className="map-id-container">
                            <Typography variant="title2">{this.state.map.id}</Typography>
                        </div>
                    </div>

                    <div className="section">
                        <div>
                            <Typography variant="subtitle1">Zoom levels</Typography>
                        </div>

                        <div className="map-id-container">
                            <Typography variant="title2">
                                {this.state.minZoom} - {this.state.maxZoom}
                            </Typography>
                        </div>
                    </div>

                    <div className="section">
                        <FormLabel component="legend">Datasets</FormLabel>
                        <div className="datasets">
                            {datasets}
                            <div className="add-button" onClick={this.onAddDatasetClick}>
                                <AddIcon className="icon" />
                                <Typography variant="title2">Add</Typography>
                            </div>
                        </div>
                    </div>
                    <div className="actions">
                        <Button className="back-btn" variant="outlined" color="primary" onClick={() => history.push("/maps")}>
                            back
                        </Button>

                        <Button className="generate-btn" variant="outlined" color="primary" disabled={!this.state.changes} onClick={this.onSaveChanges}>
                            Save changes
                        </Button>
                    </div>
                </div>
                <Dialog onClose={this.onDatasetDialogClose} aria-labelledby="simple-dialog-title" className="dataset-dialog" open={this.state.datasetDialogOpen}>
                    <DialogTitle id="simple-dialog-title" className="title">
                        Select dataset
                    </DialogTitle>
                    <div className="container">
                        <Select value={this.state.newDataset} onChange={this.onNewDatasetChange} fullWidth>
                            {menuitems}
                        </Select>
                        <Button className="add-btn" fullWidth variant="outlined" color="primary" onClick={() => this.onAddDataset()}>
                            Add
                        </Button>
                    </div>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        maps: state.mapService,
        datasetsState: state.datasets
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getMap: (mapId) => dispatch(mapServiceActions.getMap(mapId)),
        getDatasets: () => dispatch(datasetServiceActions.getDatasets()),
        addDatasetToMap: (mapId, datasteId) => dispatch(mapServiceActions.addDatasetToMap(mapId, datasteId)),
        updateMap: (mapId, map) => dispatch(mapServiceActions.updateMap(mapId, map)),
        removeDatasetFromMap: (mapId, datasteId) => dispatch(mapServiceActions.removeDatasetFromMap(mapId, datasteId)),
        generateCache: (mapId) => dispatch(mapServiceActions.generateCache(mapId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MapEditView));
