import React, { Component } from "react";
import { connect } from "react-redux";
import * as UserService from "../../../actions/userService";

import { withStyles } from "@material-ui/core/styles";
import { toastr } from "react-redux-toastr";

import Radio from "@material-ui/core/Radio";
import { Typography, LinearProgress } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import Checkbox from "@material-ui/core/Checkbox";
import { Tabs, Tab } from "@material-ui/core";

const styles = theme => ({});

let rolesInit = ["admin", "user"];

class UserEditView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                username: "",
                roles: [
                    {
                        name: "admin",
                        checked: false
                    },
                    {
                        name: "user",
                        checked: false
                    }
                ]
            },
            newPassword: "",
            passwordDialogOpen: false
        };
    }

    componentDidMount() {
        this.fetchUser(this.props.match.params.userId);
    }

    fetchUser(userId) {
        this.props.getUser(userId).then(res => {
            let roles = rolesInit.map(x => {
                return { name: x, checked: res.result.roles.includes(x) };
            });

            this.setState({
                user: {
                    id: res.result.id,
                    username: res.result.username,
                    roles: roles
                }
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.userId !== prevProps.match.params.userId) {
            this.fetchUser(this.props.match.params.userId);
        }
    }

    onUsernameChange = e => {
        this.setState({
            user: {
                ...this.state.user,
                username: e.target.value
            }
        });
    };

    onRoleChange = roleName => {
        let newRoles = this.state.user.roles.map(role => {
            if (role.name === roleName) {
                return {
                    ...role,
                    checked: !role.checked
                };
            }
            return role;
        });

        this.setState({
            user: {
                ...this.state.user,
                roles: newRoles
            }
        });
    };

    updateUser = () => {
        let user = {
            id: this.state.user.id,
            username: this.state.user.username,
            roles: this.state.user.roles.filter(x => x.checked).map(x => x.name)
        };

        this.props.updateUser(user).then(
            res => {
                toastr.success("Success", "User updated");
            },
            err => {
                toastr.error("Error", err.message);
            }
        );
    };

    onNewPasswordChange = e => {
        this.setState({
            newPassword: e.target.value
        });
    };

    onChangePasswordClick = () => {
        this.setState({
            passwordDialogOpen: true
        });
    };

    onChangePassword = () => {
        this.props.updateUserPassword(this.state.user.id, this.state.newPassword).then(
            res => {
                toastr.success("Success", "Password updated");
                this.setState({
                    passwordDialogOpen: false,
                    newPassword:''
                });
            },
            err => {
                toastr.error("Error", err.message);
            }
        );
    };

    onPasswordDialogClose = () => {
        this.setState({
            passwordDialogOpen: false
           
        });
    };

    render() {
        let { classes, match } = this.props;

        return (
            <div className="user-edit-view">
                <div className="header">
                    <Typography variant="subtitle1">Edit User</Typography>
                </div>
                {this.props.userState.fetching && <LinearProgress className="no-margin-progress" />}
                <div className="container">
                    <div className="section">
                        <div className="username-container">
                            <FormLabel>Username</FormLabel>
                            <TextField id="full-width" placeholder="Name of the user" value={this.state.user.username} onChange={this.onUsernameChange} fullWidth margin="dense" />
                        </div>
                    </div>
                    {/* <div className="section flex">
                        <div className="username-container">
                            <FormLabel component="legend">Password</FormLabel>
                            <TextField
                                id="full-width"
                                placeholder="New password"
                                value={this.state.user.password}
                                onChange={e => this.changeDatasetName("datasetName", e)}
                                fullWidth
                                margin="dense"
                            />
                        </div>
                        <div className="button-container">
                            <Button color="primary" variant="outlined" onClick={this.createUser}>
                                Update
                            </Button>
                        </div>
                    </div> */}
                    <div className="section">
                        {this.state.user.roles.map((role, index) => {
                            return <FormControlLabel control={<Checkbox checked={role.checked} onChange={() => this.onRoleChange(role.name)} />} label={role.name} />;
                        })}
                    </div>
                    <div className="section flex">
                        <Button color="primary" variant="outlined" className="button" onClick={this.updateUser}>
                            Update
                        </Button>
                        <Button color="primary" variant="outlined" className="button" onClick={this.onChangePasswordClick}>
                            Change Password
                        </Button>
                    </div>
                </div>
                <Dialog onClose={this.onPasswordDialogClose} aria-labelledby="simple-dialog-title" className="dataset-dialog" open={this.state.passwordDialogOpen}>
                    <DialogTitle id="simple-dialog-title" className="title">
                        Update Password
                    </DialogTitle>
                    <div className="container">
                        <TextField id="full-width" type="password" placeholder="New password" value={this.state.newPassword} onChange={this.onNewPasswordChange} fullWidth margin="dense" />
                        <Button className="add-btn" fullWidth variant="outlined" color="primary" onClick={() => this.onChangePassword()}>
                            Update
                        </Button>
                    </div>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userState: state.users
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getUser: userId => dispatch(UserService.getUser(userId)),
        updateUser: user => dispatch(UserService.updateUser(user)),
        updateUserPassword: (userId, password) => dispatch(UserService.changePassword(userId, password))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserEditView));
