import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as mapServiceActions from '../../../actions/mapService';
import { Route } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';


import Map from '../../../components/map/mapComponent'
import GroupsListView from '../groupsListView/groupsListView'
import EditView from '../groupEditView/groupEditView'
import NavigationMenu from '../../../components/navigationMenu'

const styles = theme => ({

});


class GroupsRootView extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }


    render() {
        let { classes, match } = this.props;

        return (
            <div className="groups-rootview">
                <NavigationMenu />
                <div className="sidebar">
                    <Route exact path={`${match.path}`} component={GroupsListView} />
                    <Route exact path={`${match.path}/edit/:groupId`} component={GroupsListView} />
                </div>

                <div className="group-edit-container">
                    <Route exact path={`${match.path}/edit/:groupId`} component={EditView} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getGroups: () => dispatch(mapServiceActions.getMaps()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(GroupsRootView));

