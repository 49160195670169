import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FilledInput from '@material-ui/core/FilledInput';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreHoriz';

import Toolbar from './propertyToolbar'
import { Checkbox, InputBase } from '@material-ui/core';

function NumberArrayProperty(props) {


    function handleChange(e) {
 
        props.onPropertyChanged(e.target.value.split(','))
    }

    return (
        <div className="property">
           
            <div className="property-content">
            {props.editable && <TextField className="textfield" onChange={props.onTitleChanged} value={props.title} />}
                {!props.editable && (
                    <Typography variant="body1" className="name">
                        {props.title}
                    </Typography>
                )}
                 <div className="text-container">
                    <InputBase value={props.value.join(',')} onChange={handleChange} className="text" />
                </div>

            </div>

        </div>
    );
}

export default NumberArrayProperty;