import React, { Component } from "react";
import { connect } from "react-redux";
import * as datasetsActions from "../../../actions/datasets";
import { Route } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";

import Map from "../../../components/map/mapComponent";
import DatasetsListView from "../datasetsListView/datasetsListView";
import DatasetEditView from "../datasetEditView/datasetEditView";
import NavigationMenu from "../../../components/navigationMenu";
import TableView from "../tableView/tableView";

const styles = theme => ({});

class DatasetsRootView extends Component {
    state = {
        sidebarOpen: true,
        showDataView: false
    };

    componentDidMount() {
        //  this.props.getDatasets()
    }

    onToggleSidebar = () => {
        this.setState({
            sidebarOpen: !this.state.sidebarOpen
        });
    };

    onToggleDataView = () => {
        this.setState({
            showDataView: !this.state.showDataView
        });
    };

    render() {
        let { classes, match } = this.props;

        return (
            <div className="datasets-rootview">
                <NavigationMenu onToggleSidebar={this.onToggleSidebar} />
                <div className={this.state.sidebarOpen ? "sidebar" : "sidebar closed"}>
                    <Route exact path={`${match.path}/`} component={DatasetsListView} />
                    <Route path={`${match.path}/edit/:datasetId`} component={DatasetEditView} />
                </div>

                <div className="map">
                    <Map />
                    {this.state.showDataView && <TableView />}
                    {this.props.tableViewState.datasetId && (
                        <div className="map-data-switch">
                            <Typography color="inherit" variant="subtitle1">
                                Map
                            </Typography>
                            <Switch
                                checked={this.state.showDataView}
                                onChange={this.onToggleDataView}
                                value="checkedA"
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                            <Typography color="inherit" variant="subtitle1">
                                Data
                            </Typography>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        tableViewState: state.tableView
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getDatasets: () => dispatch(datasetsActions.getDatasets())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatasetsRootView));
