import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { history } from "../../../store";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import DragDropIcon from "@material-ui/icons/SaveAlt";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";

import * as datasetsActions from "../../../actions/datasets";
import * as UserServiceActions from "../../../actions/userService";
import * as groupActions from "../../../actions/groupService";
import { toastr } from "react-redux-toastr";

const styles = theme => ({});

class CreateUserModal extends Component {
    state = {
        username: "",
        password: "",
        roles: [
            {
                name: "admin",
                checked: false
            },
            {
                name: "user",
                checked: false
            }
        ],
        validated: { username: true, password: true, roles: true }
    };

    componentDidMount() {}

    changeUsername = e => {
        let usernameValid = e.target.value != "";

        this.setState({
            username: e.target.value,
            validated: {
                ...this.state.validated,
                username: usernameValid
            }
        });
    };

    changePassword = e => {
        let passwordValid = e.target.value != "";

        this.setState({
            password: e.target.value,
            validated: {
                ...this.state.validated,
                password: passwordValid
            }
        });
    };

    onRoleChange(prop) {
        let newRoles = this.state.roles.map(x => {
            if (x.name == prop) {
                return { ...x, checked: !x.checked };
            }
            return x;
        });

        this.setState({
            roles: newRoles,
            validated: {
                ...this.state.validated,
                roles: newRoles.some(x => x.checked)
            }
        });
    }

    validateInfo() {
        let usernameValid = this.state.username != "";
        let passwordValid = this.state.password != "";
        let rolesValid = this.state.roles.some(x => x.checked);

        this.setState({
            validated: {
                username: usernameValid,
                password: passwordValid,
                roles: rolesValid
            }
        });

        return usernameValid && passwordValid && rolesValid;
    }

    createUser = () => {
        if (!this.validateInfo()) {
            return;
        }

        let request = {
            username: this.state.username,
            password: this.state.password,
            roles: this.state.roles.filter(x => x.checked).map(x => x.name)
        };

        this.props.createUser(request).then(
            res => {
                this.props.handleClose();
                toastr.success("Success", "User created");
            },
            err => {
                toastr.error("Error", err.message);
            }
        );
    };

    render() {
        let { props } = this;
        let { classes } = props;
        return (
            <div>
                <Modal
                    className="atlas-modal create-user-modal"
                    open={props.open}
                    onClose={props.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500
                    }}
                >
                    <Fade in={props.open}>
                        <div className="container">
                            <Typography variant="h6" id="transition-modal-title" className="header">
                                Create User
                            </Typography>
                            <div className="page">
                                <div className="section">
                                    <Typography variant="body1">Username</Typography>
                                    <TextField
                                        id="full-width"
                                        placeholder="username"
                                        value={this.state.username}
                                        onChange={this.changeUsername}
                                        fullWidth
                                        error={!this.state.validated.username}
                                        margin="none"
                                    />
                                </div>
                                <div className="section">
                                    <Typography variant="body1">password</Typography>
                                    <TextField
                                        id="full-width"
                                        placeholder="password"
                                        value={this.state.password}
                                        onChange={this.changePassword}
                                        fullWidth
                                        error={!this.state.validated.password}
                                        margin="none"
                                    />
                                </div>

                                <div className="section">
                                    <Typography variant="body1">Roles</Typography>
                                    {this.state.roles.map((role, index) => {
                                        return (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={role.checked}
                                                        onChange={() => this.onRoleChange(role.name)}
                                                    />
                                                }
                                                label={role.name}
                                            />
                                        );
                                    })}
                                    {!this.state.validated.roles && (
                                        <FormHelperText error>Select atleast one group</FormHelperText>
                                    )}
                                </div>

                                <div className="button-container">
                                    <Button color="primary" variant="outlined" onClick={this.createUser}>
                                        Create
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        createUser: createUserRequest => dispatch(UserServiceActions.createUser(createUserRequest))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreateUserModal));
