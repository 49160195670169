import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";

import DatasetIcon from "@material-ui/icons/Storage";
import MapIcon from "@material-ui/icons/Map";
import GroupIcon from "@material-ui/icons/Group";
import UserIcon from "@material-ui/icons/Person";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AccountBox from "@material-ui/icons/AccountBox";

import * as authActions from "../actions/auth";

import { history } from "../store";
import { MenuItem, Menu } from "@material-ui/core";

let navigationLocations = [
    {
        name: "Datasets",
        route: "/datasets",
        icon: <DatasetIcon className="icon" />
    },
    {
        name: "Maps",
        route: "/maps",
        icon: <MapIcon className="icon" />
    },
    {
        name: "Groups",
        route: "/groups",
        icon: <GroupIcon className="icon" />
    },
    {
        name: "Users",
        route: "/users",
        icon: <UserIcon className="icon" />
    }
];

function NavigationMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    function onClick(route) {
        history.push(route);
    }

    const signOut = (e) => {
        props.logout();
        history.replace('/')
        setAnchorEl(null);
    };

    const onAccountclick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleCloseAccountMenu = () => {
        setAnchorEl(null);
    };

    return (
        <div className="navigation-menu">
            <div className="header" onClick={props.onToggleSidebar}>
                <ArrowBackIosIcon className="icon" style={{ marginLeft: 10 }} />
            </div>
            <div className="container">
                {navigationLocations.map((item, index) => {
                    return (
                        <Tooltip title={item.name} placement="right" key={index}>
                            <div className={props.location.pathname.startsWith(item.route) ? "button active" : "button"} onClick={() => onClick(item.route)}>
                                {item.icon}
                            </div>
                        </Tooltip>
                    );
                })}
                {/* <div className="flex-grow"></div> */}
                <Tooltip title="Account" placement="right">
                    <div className="logout-button" onClick={onAccountclick}>
                        <AccountBox className="icon" />
                    </div>
                </Tooltip>
                <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseAccountMenu}>
                    <MenuItem onClick={signOut}>Logout</MenuItem>
                </Menu>
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        logout: () => dispatch(authActions.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavigationMenu));
