import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ChromePicker } from 'react-color';



function SimplePopover(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : null;

    return (
        <div>
            <div className='color-container' style={{backgroundColor:props.color}} onClick={handleClick}>

            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{
                    style:{marginLeft:8,overflowY:'hidden',    userSelect: 'none'}
                }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}
            >
                <ChromePicker color={props.color} onChangeComplete={props.onColorChanged} />
            </Popover>
        </div>
    );
}

export default SimplePopover;