import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import AddIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";

import SearchList from "../../../components/searchList";

import CreateModal from "./createUserModal";

import * as userActions from "../../../actions/userService";
import { history } from "../../../store";
import { toastr } from "react-redux-toastr";

const styles = theme => ({
    title: {
        flexGrow: 1
    }
});

class UsersListView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: null,
            search: "",
            modalOpen: false
        };
    }

    componentDidMount() {}

    onUserClick(item) {
        this.setState({
            selected: item.id
        });
        history.push("/users/edit/" + item.id);
    }

    onSearch = e => {
        this.setState({
            search: e.target.value
        });
    };

    onAddUserClick = () => {
        this.setState({
            modalOpen: true
        });
    };

    onModalClose = () => {
        this.setState({
            modalOpen: false
        });
    };

    deleteUser = user => {
        this.props.deleteUser(user.id).then(
            res => {
                toastr.success("Success", "User deleted");
            },
            err => {
                toastr.error("Error", err.message);
            }
        );
    };

    onDeleteUser = (e, user) => {
        e.stopPropagation();

        const toastrConfirmOptions = {
            onOk: () => this.deleteUser(user),
            onCancel: () => console.log("CANCEL: clicked")
        };
        toastr.confirm("Delete user " + user.username, toastrConfirmOptions);
    };

    render() {
        let { classes } = this.props;

        let users = this.props.usersState.users
            .filter(x => x.username.includes(this.state.search))
            .slice(0, 10)
            .map((user, index) => {
                let selected = user.id === this.state.selected;

                return (
                    <div key={index} className={selected ? "user selected" : "user"} onClick={() => this.onUserClick(user)}>
                        <div className="name">{user.username}</div>
                        <Tooltip id="tooltip-top" title="Delete">
                            <div className="button">
                                <DeleteIcon className="icon" onClick={e => this.onDeleteUser(e, user)} />
                            </div>
                        </Tooltip>
                    </div>
                );
            });

        return (
            <div className="sidebar-container users-overview">
                <CreateModal open={this.state.modalOpen} handleClose={this.onModalClose} />
                <AppBar position="static">
                    <Toolbar className="toolbar">
                        <img src="/Logo.svg"></img>
                    </Toolbar>
                </AppBar>
                <div className="header">
                    <Typography variant="h6" className={classes.title}>
                        Users
                    </Typography>
                    <Button color="primary" variant="contained" onClick={this.onAddUserClick}>
                        <AddIcon style={{ marginRight: 8, marginLeft: -8 }} />
                        Add User
                    </Button>
                </div>
                {this.props.usersState.fetching && <LinearProgress className="no-margin-progress" />}
                <div className="content">
                    <div className="search">
                        <TextField
                            id="full-width"
                            inputProps={{ autoComplete: "off" }}
                            placeholder="Search users"
                            value={this.state.search}
                            onChange={this.onSearch}
                            fullWidth
                            margin="dense"
                        />
                    </div>
                    <div className="item-container">{users}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        usersState: state.users
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getUsers: () => dispatch(userActions.getUsers()),
        deleteUser: userId => dispatch(userActions.deleteUser(userId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UsersListView));
