import React, { useState } from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MenuIcon from "@material-ui/icons/MoreVert";
import StorageIcon from "@material-ui/icons/Storage";
import ModifiedIcon from "@material-ui/icons/History";
import CreatedIcon from "@material-ui/icons/Schedule";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { GeometryIcons } from "../../../../utils/customIcons";
import SvgIcon from "@material-ui/core/SvgIcon";
import moment from "moment";

export default function Dataset(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const onMenuClick = event => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const onMenuClose = () => {
        setAnchorEl(null);
    };

    const onEditClick = () => {
        onMenuClose();
        props.onEditClick(props.dataset);
    };

    const onDeleteClick = () => {
        onMenuClose();
        props.onDeleteClick(props.dataset);
    };

    const renderGeometryType = geometryType => {
        switch (geometryType) {
            case "POLYGON":
            case "MULTIPOLYGON":
                return <SvgIcon>{GeometryIcons.polygon}</SvgIcon>;
            case "LINESTRING":
            case "MULTILINESTRING":
                return <SvgIcon>{GeometryIcons.line}</SvgIcon>;
            case "POINT":
            case "MULTIPOINT":
                return <SvgIcon>{GeometryIcons.point}</SvgIcon>;
        }
    };

    return (
        <div className={props.selected ? "dataset selected" : "dataset"} onDoubleClick={e => props.onDoubleClick(e, props.dataset)}>
            <div className="container">
                <div className="row-container" onClick={() => props.OnClick(props.dataset)}>
                    <div className={props.dataset.cached ? "row-1 cached" : "row-1 uncached"}>
                        <Tooltip id="tooltip-top" title={props.dataset.cached ? "cached" : "uncached"}>
                            <div className="geometry-icon">{renderGeometryType(props.dataset.geometryType)}</div>
                        </Tooltip>
                        <Tooltip id="tooltip-top" title="">
                            <div className="schema">{props.dataset.schemaName}</div>
                        </Tooltip>
                        <div className="name">{props.dataset.name}</div>
                        <Tooltip
                            id="tooltip-top"
                            title={moment(props.dataset.modifiedUtc)
                                .local()
                                .format("MMMM Do YYYY, h:mm:ss a")}
                        >
                            <div className="date">{moment(props.dataset.modifiedUtc).fromNow()}</div>
                        </Tooltip>

                        <div className="button" onClick={onMenuClick}>
                            <MenuIcon />
                        </div>
                    </div>
                    {/* <div className="row-2">

                        <StorageIcon className="icon" />
                        <div variant="caption" className="row-count">
                            {props.dataset.rowCount} rows | {props.dataset.databaseSize}
                        </div>

                        <ModifiedIcon className="icon" />
                        <Tooltip id="tooltip-top" title={"modified: " + moment(props.dataset.modifiedUtc).format('MMMM Do YYYY, h:mm:ss a')}>
                            <div className="created">
                                {moment(props.dataset.modifiedUtc).fromNow()}
                            </div>
                        </Tooltip>

                        <CreatedIcon className="icon" />
                        <Tooltip id="tooltip-top" title={"created: " + moment(props.dataset.createdUtc).format('MMMM Do YYYY, h:mm:ss a')}>
                            <div className="created grow">
                                {moment(props.dataset.createdUtc).fromNow()}
                            </div>
                        </Tooltip>

                    </div> */}
                </div>

                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onMenuClose}>
                    <MenuItem onClick={onEditClick} className="menu-button">
                        <EditIcon className="icon" />
                        Edit
                    </MenuItem>
                    <MenuItem onClick={onEditClick} className="menu-button">
                        <EditIcon className="icon" />
                        Generate
                    </MenuItem>
                    <MenuItem onClick={onDeleteClick} className="menu-button">
                        <DeleteIcon className="icon" />
                        Delete
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
}
