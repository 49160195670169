import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import * as mapActions from "../../../../actions/map";
import * as datasetActions from "../../../../actions/datasets";

import styleConfig from "./styleConfig";
import PropertyButton from "./propertyButton";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FilledInput from "@material-ui/core/FilledInput";
import MenuItem from "@material-ui/core/MenuItem";

import { GeometryIcons } from "../../../../utils/customIcons";

import AddIcon from "@material-ui/icons/Add";
import FillIcon from "@material-ui/icons/Category";
import LineIcon from "@material-ui/icons/Share";
import SymbolIcon from "@material-ui/icons/FontDownload";

import Typography from "@material-ui/core/Typography";
import ColorProperty from "./styleProperties/colorProperty/colorProperty";
import MultiColorProperty from "./styleProperties/multiColorProperty";
import NumberProperty from "./styleProperties/numberProperty/numberProperty";
import FloatProperty from "./styleProperties/numberProperty/floatProperty";
import TextProperty from "./styleProperties/textProperty";
import SelectProperty from "./styleProperties/selectProperty";
import BooleanProperty from "./styleProperties/booleanProperty";
import StyleProperty from "./styleProperty";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import RcTooltip from "rc-tooltip";
import { Tab, Tabs } from "@material-ui/core";

const Handle = Slider.Handle;

const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
        <RcTooltip prefixCls="rc-slider-tooltip" overlay={value} placement="top" key={index}>
            <Handle value={value} {...restProps} />
        </RcTooltip>
    );
};

const StyledTabs = withStyles({
    root: {
        height: 32,
        minHeight: 32
    },
    indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent"
    }
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        height: 32,
        minHeight: 32,
        width: "50%",
        padding: 0,

        "&:focus": {
            opacity: 1
        },
        "&:hover": {
            filter: "brightness(75%)"
        }
    },
    selected: {
        backgroundColor: theme.palette.background,
        "&:hover": {
            filter: "none"
        }
    }
}))((props) => <Tab {...props} />);

const styles = (theme) => ({
    root: {},
    selectValue: {
        display: "flex",
        alignItems: "center",
        fontSize: 16
    }
});

let transformDict = {
    color: (x) => x,
    "multi-color": (x) => x,
    number: (x) => parseInt(x),
    numberArray: (x) => x.map((y) => parseFloat(y)),
    float: (x) => parseFloat(x),
    text: (x) => x,
    select: (x) => x,
    boolean: (x) => x
};

let layerTypes = {
    fill: {
        name: "fill",
        namePretty: "Draw as Polygons",
        icon: GeometryIcons.polygon
    },
    line: {
        name: "line",
        namePretty: "Draw as Lines",
        icon: GeometryIcons.line
    },
    circle: {
        name: "circle",
        namePretty: " Draw as Points",
        icon: GeometryIcons.point
    },
    symbol: {
        name: "symbol",
        namePretty: "Draw as Labels",
        icon: <SymbolIcon></SymbolIcon>
    }
};

class Style extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedProperty: { name: "" },
            columns: {},
            tab: "data"
        };
    }

    onPropertySelected = (property) => {
        this.setState({
            selectedProperty: property
        });
    };

    componentDidMount() {

        let columnPromises = this.props.datasets.map(x => this.props.getDatasetColumns(x.id))

        let columnsMap = {}

        Promise.all(columnPromises).then((responses) => {
            
            for (let i = 0; i < responses.length; i++){
                let response = responses[i];
                columnsMap[response.id] = response.result

            }
            
            this.setState({
                columns: columnsMap
            });
        })
   
    }

    componentDidUpdate(prevProps) {
        if (this.props.layer.layerId !== prevProps.layer.layerId) {
            this.setState({
                selectedProperty: this.props.layer.properties[0]
            });
        }
        if (this.props.layer.type !== prevProps.layer.type) {
            this.setState({
                selectedProperty: this.props.layer.properties[0]
            });
        }
    }

    onChangeType = (type) => {
        this.setState({
            selectedType: type
        });

        this.props.onTypeChanged(type);
    };

    onChangePropertyType = (prop, type, value) => {
        let newProperties = this.props.layer.properties.map((item) => {
            if (item.name === prop) {
                return {
                    ...item,
                    propertyType: type,
                    value: value
                };
            }
            return item;
        });

        this.props.onPropertiesChanged(newProperties);
    };

    onChangePropertyExpressionType = (prop, type, value) => {
        let newProperties = this.props.layer.properties.map((item) => {
            if (item.name === prop) {
                return {
                    ...item,
                    expressionType: type,
                    value: value
                };
            }
            return item;
        });

        this.props.onPropertiesChanged(newProperties);
        this.updateMap(newProperties);
        this.setState({
            selectedProperty: {
                ...this.state.selectedProperty,
                expressionType: type,
                value: value
            }
        });
    };

    onPaintPropertyChanged = (property, value) => {
        let newProperties = this.props.layer.properties.map((item) => {
            if (item.name === property.name) {
                return {
                    ...item,
                    value: property.expressionType !== "none" ? value : transformDict[property.propertyType](value)
                };
            }
            return item;
        });

        this.setState({
            properties: newProperties,
            selectedProperty: {
                ...this.state.selectedProperty,
                value: value
            }
        });

        this.updateMap(newProperties);
    };

    updateMap(properties) {
        let paintProperties = properties.filter((x) => x.type === "paint");

        let paint = {
            layerId: this.props.layer.layerId,
            properties: paintProperties
        };

        let layoutProperties = properties.filter((x) => x.type === "layout");

        let layout = {
            layerId: this.props.layer.layerId,
            properties: layoutProperties
        };

        this.props.changeMapPaint(paint);
        this.props.changeMapLayout(layout);

        this.props.onPropertiesChanged(properties);
    }

    // addStyle = () => {
    //     let style = {
    //         type: this.state.selectedType,
    //         properties: this.state.properties[this.state.selectedType]
    //     }

    //     this.props.addStyle(this.props.mapState.layer.id, 'test', style)
    // }

    // updateStyle = () => {

    //     let styleProperties = {
    //         type: this.state.selectedType,
    //         properties: this.state.properties[this.state.selectedType]
    //     }

    //     let newStyle = {
    //         id: this.state.styleId,
    //         name: 'test',
    //         properties: styleProperties
    //     }

    //     this.props.updateStyle(this.props.mapState.layer.sourceId, newStyle)
    // }

    onChangeDataset = (e) => {
        let newName = e.target.value;

        let dataset = this.props.datasets.find((x) => x.name === newName);

        this.props.onChangeDataset(dataset);
    };

    onChangeTab = (tab) => {
        this.setState({
            tab: tab
        });
    };

    onChangeLayerName = (e) => {
        this.props.onNameChanged(e.target.value);
    };

    getRenderTypes = (geometryType) => {
        let fill = layerTypes["fill"];
        let line = layerTypes["line"];
        let circle = layerTypes["circle"];
        let symbol = layerTypes["symbol"];

        switch (geometryType) {
            case "POLYGON":
            case "MULTIPOLYGON":
                return [fill, line, circle, symbol];
            case "POINT":
            case "MULTIPOINT":
                return [circle, symbol];
            case "LINESTRING":
            case "MULTILINESTRING":
                return [line, circle, symbol];
        }
    };

    renderProperty(item, index) {
        return (
            <PropertyButton
                type={item.propertyType}
                selected={this.state.selectedProperty.name === item.name}
                expressionType={item.expressionType}
                key={index}
                value={item.value.toString()}
                title={item.title}
                onClick={() => this.onPropertySelected(item)}
            />
        );
    }

    render() {
        let { classes } = this.props;
        let properties = this.props.layer.properties.map((item, index) => this.renderProperty(item, index));

        let supportedRenderTypes = this.getRenderTypes(this.props.layer.dataset.geometryType);

        let layertypeEls = supportedRenderTypes.map((type, index) => {
            return (
                <Tooltip title={type.namePretty} key={index}>
                    <div className={this.props.layer.type === type.name ? "type active" : "type"} onClick={() => this.onChangeType(type.name)}>
                        {type.icon}
                    </div>
                </Tooltip>
            );
        });

        return (
            <div className="style">
                <div className="tabs">
                    <StyledTabs value={this.state.tab} TabIndicatorProps={<div />}>
                        <StyledTab label="data" onClick={() => this.onChangeTab("data")} value="data" />
                        <StyledTab label="style" onClick={() => this.onChangeTab("style")} value="style" />
                    </StyledTabs>
                </div>

                <div className="style-content">
                    {this.state.tab === "data" && (
                        <div className="data-tab">
                            <div className="section-container">
                                <div className="section">
                                    <FormLabel component="legend">Layer name</FormLabel>
                                    <TextField
                                        id="full-width"
                                        placeholder="Name of the layer"
                                        value={this.props.layer.name}
                                        onChange={this.onChangeLayerName}
                                        fullWidth
                                        margin="dense"
                                    />
                                </div>

                                <div className="section">
                                    <FormLabel component="legend">Dataset</FormLabel>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth margin="dense">
                                        <Select
                                            value={this.props.layer.dataset.name}
                                            onChange={this.onChangeDataset}
                                            className="select"
                                            inputProps={{
                                                name: "age",
                                                id: "outlined-age-simple",
                                                classes: {
                                                    select: classes.selectValue
                                                }
                                            }}
                                            // MenuProps={{
                                            //     classes:{
                                            //         list:classes.menuList
                                            //     }
                                            // }}
                                        >
                                            {this.props.datasets.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item.name}>
                                                        <div className="menu-item-name">{item.name}</div>
                                                        <div className="menu-item-geometry-type">{item.geometryType}</div>
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="section-container">
                                <FormLabel component="legend">Zoom</FormLabel>
                                <div className="slider-container">
                                    <Range
                                        max={24}
                                        min={this.props.map.minZoom}
                                        handle={handle}
                                        pushable={2}
                                        value={[this.props.layer.minZoom, this.props.layer.maxZoom]}
                                        onChange={(value) => this.props.onZoomSliderChange(value)}
                                    />
                                    <div className="slider-footer">
                                        <span>{this.props.map.minZoom}</span>
                                        <span>24</span>
                                    </div>
                                </div>
                            </div>

                            <div className="section-container">
                                <div className="types">{layertypeEls}</div>
                            </div>
                        </div>
                    )}
                    {this.state.tab === "style" && (
                        <div className="style-tab">
                            <div className="style-properties">{properties}</div>

                            {this.state.selectedProperty.name !== "" && (
                                <div className="property-container">
                                    <StyleProperty
                                        property={this.state.selectedProperty}
                                        layerType={this.props.layer.type}
                                        dataset={this.props.layer.dataset}
                                        columns={this.state.columns[this.props.layer.dataset.id]}
                                        onPropertyChanged={(property, value) => this.onPaintPropertyChanged(property, value)}
                                        onPropertyExpressionTypeChanged={(type, value) => this.onChangePropertyExpressionType(this.state.selectedProperty.name, type, value)}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        mapState: state.map
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        addMapLayer: (layer) => dispatch(mapActions.addLayer(layer)),
        updateMapLayer: (layer) => dispatch(mapActions.updateLayer(layer)),
        addMapPaint: (paint) => dispatch(mapActions.addPaint(paint)),
        addMapLayout: (layout) => dispatch(mapActions.addLayout(layout)),
        changeMapPaint: (paint) => dispatch(mapActions.updatePaint(paint)),
        changeMapLayout: (layout) => dispatch(mapActions.updateLayout(layout)),
        getDatasetColumns: (id) => dispatch(datasetActions.getDatasetColumns(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Style));
