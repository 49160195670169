import * as types from "./actionTypes";
import axiosClient from "./apiClient";

export function authenticate(username, password, remberMe) {
    return (dispatch) => {
        dispatch(authenticating());
        return axiosClient.post("auth", { username, password, remberMe }).then(
            (res) => {
                dispatch(authenticated(res));
                return res;
            },
            (fail) => {
                dispatch(authenticatingFailed(fail));
                return fail;
            }
        );
    };
}

export function authenticateRefreshToken(token) {
    return (dispatch) => {
        dispatch(authenticating());
        return axiosClient.post("auth/refresh", { token }).then(
            (res) => {
                dispatch(authenticated(res));
                return res;
            },
            (fail) => {
                dispatch(authenticatingFailed(fail));
                return fail;
            }
        );
    };
}

export function logout() {
    return (dispatch) => {
        dispatch(authenticating());
        return axiosClient.post("auth/logout").then();
    };
}

function authenticating() {
    return { type: types.AUTHENTICATING };
}

function authenticated(result) {
    return { type: types.AUTHENTICATED, result: result.data };
}

function authenticatingFailed(result) {
    return { type: types.AUTHENTICATING_FAILED, result: result.data };
}
