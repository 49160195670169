import React from 'react';
import { Route, Router } from 'react-router-dom'
import { history } from './store'
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './muiTheme'
import ReduxToastr from 'react-redux-toastr'
import './scss/main.scss'


import LoginView from './views/login/loginView/loginView'
import DatasetsView from './views/datasets/datasetsRootView/datasetsRootView'
import MapsView from './views/maps/MapsRootView/mapsRootView'
import GroupsView from './views/groups/groupsRootView/groupsRootView'
import UsersView from './views/users/usersRootView/usersRootView'


function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">

        <Router history={history}>

          <Route exact path="/" component={LoginView} />
          <Route path="/datasets" component={DatasetsView} />
          <Route path="/maps" component={MapsView} />
          <Route path="/groups" component={GroupsView} />
          <Route path="/users" component={UsersView} />
        </Router>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={true}
          position="top-right"
          getState={(state) => state.toastr} // This is the default
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          closeOnToastrClick />
      </div>
    </MuiThemeProvider>

  );
}

export default App;
