import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { ChromePicker } from "react-color";

import * as datasetsActions from "../../../../actions/datasets";

import * as ColorUtils from "../../../../utils/colorUtils";

import ColorProperty from "./styleProperties/colorProperty/colorProperty";
import MultiColorProperty from "./styleProperties/multiColorProperty";
import NumberProperty from "./styleProperties/numberProperty/numberProperty";
import FloatProperty from "./styleProperties/numberProperty/floatProperty";
import TextProperty from "./styleProperties/textProperty";
import SelectProperty from "./styleProperties/selectProperty";
import BooleanProperty from "./styleProperties/booleanProperty";
import NumberArrayProperty from "./styleProperties/numberArrayProperty";

import TextField from "@material-ui/core/TextField";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import RefreshIcon from "@material-ui/icons/Refresh";
import CategoryOutlinedIcon from "@material-ui/icons/CategoryOutlined";
import GradientOutlinedIcon from "@material-ui/icons/GradientOutlined";
import ZoomIcon from "@material-ui/icons/LineStyle";
import { Button, Typography, IconButton, Tooltip, Divider } from "@material-ui/core";

import GraduateModal from "./graduateModal";
import styleConfig from "./styleConfig";

const styles = (theme) => ({
    root: {}
});

let transformDict = {
    color: (x) => x,
    "multi-color": (x) => x,
    number: (x) => parseInt(x),
    numberArray: (x) => x,
    float: (x) => parseFloat(x),
    text: (x) => x,
    select: (x) => x,
    boolean: (x) => x
};

class StyleProperty extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categoriseDialogOpen: false,
            graduateModalOpen: false,
            selectedColumn: props.columns[0]
        };
    }

    onSwitchToCategorised = () => {
        let matchArray = ["match", ["get", this.state.selectedColumn.name]];

        this.props.getDistinctColumnValues(this.props.dataset.id, this.state.selectedColumn.name).then((res) => {
            let valueRange = this.getValueRange(this.props.property.propertyType, res.result.length, this.props.property.options);

            for (let i = 0; i < res.result.length; i++) {
                let uniqueColumnValue = res.result[i];

                switch (this.state.selectedColumn.type.toLowerCase()) {
                    case "smallint":
                    case "integer":
                    case "bigint":
                    case "decimal":
                    case "numeric":
                    case "real":
                    case "double precision":
                    case "serial":
                    case "bigserial":
                        matchArray.push(parseFloat(uniqueColumnValue), valueRange[i]);
                        break;
                    default:
                        matchArray.push(uniqueColumnValue, valueRange[i]);
                }
            }
            matchArray.push(valueRange[valueRange.length - 1]);
            this.props.onPropertyExpressionTypeChanged("match", matchArray);
            this.setState({
                categoriseDialogOpen: false
            });
        });
    };

    getValueRange(type, amount, options) {
        let valueRange = [];

        for (let i = 0; i < amount; i++) {
            switch (type) {
                case "color":
                    valueRange.push(ColorUtils.getColor(i));
                    break;
                case "number":
                    valueRange.push(1);
                    break;
                case "numberArray":
                    valueRange.push([1, 1]);
                    break;
                case "float":
                    valueRange.push(1.0);
                    break;
                case "text":
                    valueRange.push("");
                    break;
                case "select":
                    valueRange.push(options[0]);
                    break;
                case "boolean":
                    valueRange.push(true);
                    break;
            }
        }

        return valueRange;
    }

    onSwitchToGraduated = (interpolateArray) => {
        this.props.onPropertyExpressionTypeChanged("interpolate", interpolateArray);
    };

    onSwitchToZoomInterpolate = () => {
        let interpolateArray = ["interpolate", ["linear"], ["zoom"]];

        let config = styleConfig[this.props.layerType].find((x) => x.name === this.props.property.name);

        interpolateArray.push(5, config.value);
        interpolateArray.push(18, config.value);

        this.props.onPropertyExpressionTypeChanged("interpolate", interpolateArray);
    };

    onReset = (property) => {
        let config = styleConfig[this.props.layerType].find((x) => x.name === property.name);

        this.props.onPropertyExpressionTypeChanged("none", config.value);
    };

    renderProperty(property) {
        switch (property.expressionType) {
            case "none":
                return this.renderNoExpression(property);
            case "match":
                return this.renderMatchExpression(property.propertyType, property.value, property.options);
            case "interpolate":
                return this.renderInterpolateExpression(property.propertyType, property.value, property.options);
            default:
                return this.renderNoExpression(property);
        }
    }

    renderNoExpression(property) {
        switch (property.propertyType) {
            case "color":
                return <ColorProperty value={property.value} title="Color" onPropertyChanged={(value) => this.onPropertyChanged(property, value)} />;
            case "number":
                return <NumberProperty value={property.value} title="Number" onPropertyChanged={(value) => this.onPropertyChanged(property, value)} />;
            case "numberArray":
                return <NumberArrayProperty value={property.value} title="Array" onPropertyChanged={(value) => this.onPropertyChanged(property, value)} />;
            case "float":
                return <FloatProperty value={property.value} title="Decimal Number" onPropertyChanged={(value) => this.onPropertyChanged(property, value)} />;
            case "text":
                return <TextProperty value={property.value} title="Text" onPropertyChanged={(value) => this.onPropertyChanged(property, value)} />;
            case "select":
                return <SelectProperty value={property.value} title="Select" options={property.options} onPropertyChanged={(value) => this.onPropertyChanged(property, value)} />;
            case "boolean":
                return <BooleanProperty value={property.value} title="Boolean" onPropertyChanged={(value) => this.onPropertyChanged(property, value)} />;
        }
    }

    onPropertyChanged(property, value) {
        this.props.onPropertyChanged(property, value);
    }

    onPropertyTitleChanged = (e, type, index) => {
        console.log(e.target.value + " " + index);
        console.log(this.props.property.value);

        if (this.props.property.expressionType === "interpolate") {
            this.props.property.value[index - 1] = parseFloat(e.target.value);
        }
        if (this.props.property.expressionType === "match") {
            let columnName = this.props.property.value[1][1];
            let column = this.props.columns.find((x) => x.name === columnName);

            switch (column.type.toLowerCase()) {
                case "smallint":
                case "integer":
                case "bigint":
                case "decimal":
                case "numeric":
                case "real":
                case "double precision":
                case "serial":
                case "bigserial":
                    this.props.property.value[index - 1] = parseFloat(e.target.value);
                    break;
                default:
                    this.props.property.value[index - 1] = e.target.value;
            }
        }

        this.props.onPropertyChanged(type, this.props.property.value);
    };

    renderInterpolateExpression(type, values, options) {
        let properties = [];

        let propertyIndex = 0;
        for (let i = 3; i < values.length - 1; i += 2) {
            properties.push({
                title: values[i],
                value: values[i + 1],
                index: 4 + 2 * propertyIndex++
            });
        }

        return properties.map((prop, index) => {
            return this.renderExpressionValue(type, prop.title, prop.value, prop.index, options);
        });
    }

    renderMatchExpression(type, values, options) {
        let properties = [];

        let propertyIndex = 0;
        for (let i = 2; i < values.length - 1; i += 2) {
            properties.push({
                title: values[i],
                value: values[i + 1],
                index: 3 + 2 * propertyIndex++
            });
        }

        properties.push({
            title: "Default",
            value: values[values.length - 1],
            index: values.length - 1
        });

        return properties.map((prop, index) => {
            return this.renderExpressionValue(type, prop.title, prop.value, prop.index, options);
        });
    }

    renderExpressionValue(type, title, value, index, options) {
        switch (type) {
            case "color":
                return (
                    <ColorProperty
                        value={value}
                        editable={true}
                        title={title}
                        onTitleChanged={(e) => this.onPropertyTitleChanged(e, type, index)}
                        onPropertyChanged={(value) => this.onExpressionPropertyChanged(index, type, value)}
                    />
                );
            case "number":
                return (
                    <NumberProperty
                        value={value}
                        editable={true}
                        title={title}
                        onTitleChanged={(e) => this.onPropertyTitleChanged(e, type, index)}
                        onPropertyChanged={(value) => this.onExpressionPropertyChanged(index, type, value)}
                    />
                );
            case "numberArray":
                return (
                    <NumberArrayProperty
                        value={value}
                        editable={true}
                        title={title}
                        onTitleChanged={(e) => this.onPropertyTitleChanged(e, type, index)}
                        onPropertyChanged={(value) => this.onExpressionPropertyChanged(index, type, value)}
                    />
                );
            case "float":
                return (
                    <FloatProperty
                        editable={true}
                        title={title}
                        onTitleChanged={(e) => this.onPropertyTitleChanged(e, type, index)}
                        onPropertyChanged={(value) => this.onExpressionPropertyChanged(index, type, value)}
                    />
                );
            case "text":
                return (
                    <TextProperty
                        value={value}
                        editable={true}
                        title={title}
                        onTitleChanged={(e) => this.onPropertyTitleChanged(e, type, index)}
                        onPropertyChanged={(value) => this.onExpressionPropertyChanged(index, type, value)}
                    />
                );
            case "select":
                return (
                    <SelectProperty
                        value={value}
                        editable={true}
                        title={title}
                        onTitleChanged={(e) => this.onPropertyTitleChanged(e, type, index)}
                        onPropertyChanged={(value) => this.onExpressionPropertyChanged(index, type, value)}
                    />
                );
            case "boolean":
                return (
                    <BooleanProperty
                        value={value}
                        editable={true}
                        title={title}
                        onTitleChanged={(e) => this.onPropertyTitleChanged(e, type, index)}
                        onPropertyChanged={(value) => this.onExpressionPropertyChanged(index, type, value)}
                    />
                );
        }
    }

    onExpressionPropertyChanged = (index, type, value) => {
        let test = this.props.property.value;

        this.props.property.value[index] = transformDict[type](value);

        this.props.onPropertyChanged(type, this.props.property.value);
    };

    onCategoriseClick = () => {
        this.setState({
            categoriseDialogOpen: true
        });
    };

    onCategoriseDialogClose = () => {
        this.setState({
            categoriseDialogOpen: false
        });
    };

    onColumnChange = (e) => {
        let column = this.props.columns.find((x) => x.name === e.target.value);

        this.setState({
            selectedColumn: column
        });
    };

    onGraduateClick = () => {
        this.setState({
            graduateModalOpen: true
        });
    };

    onGraduateDialogClose = () => {
        this.setState({
            graduateModalOpen: false
        });
    };

    render() {
        let { classes, property, columns } = this.props;

        let values = this.renderProperty(property);

        let menuitems = columns.map((item, index) => {
            return (
                <MenuItem key={index} value={item.name} className="menu-item-flex">
                    <div className="name">{item.name}</div>
                    <span className="type">{item.type}</span>
                </MenuItem>
            );
        });

        return (
            <div className="style-property">
                <div className="property-title">
                    <div className="actions">
                        <Tooltip title="Graduate">
                            <IconButton size="medium" onClick={this.onGraduateClick}>
                                <GradientOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Categorise">
                            <IconButton size="medium" onClick={this.onCategoriseClick}>
                                <CategoryOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Zoom dependant">
                            <IconButton size="medium" onClick={this.onSwitchToZoomInterpolate}>
                                <ZoomIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip size="medium" title="Reset">
                            <IconButton onClick={() => this.onReset(property)}>
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    {/* <Typography gutterBottom className="property-title-type" variant="subtitle1">
                        {property.propertyType}
                    </Typography> */}
                </div>
                <div className="values">{values}</div>

                <Dialog onClose={this.onCategoriseDialogClose} aria-labelledby="simple-dialog-title" className="dataset-dialog" open={this.state.categoriseDialogOpen}>
                    <DialogTitle id="simple-dialog-title" className="title">
                        Select Column
                    </DialogTitle>
                    <div className="container">
                        <Select value={this.state.selectedColumn.name} onChange={this.onColumnChange} fullWidth>
                            {menuitems}
                        </Select>
                        <Button className="add-btn" fullWidth variant="outlined" color="primary" onClick={() => this.onSwitchToCategorised()}>
                            Categorise
                        </Button>
                    </div>
                </Dialog>
                <GraduateModal
                    open={this.state.graduateModalOpen}
                    columns={columns}
                    datasetId={this.props.dataset.id}
                    onFinish={this.onSwitchToGraduated}
                    onClose={this.onGraduateDialogClose}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        mapState: state.map
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getDistinctColumnValues: (datasetId, columnName) => dispatch(datasetsActions.getDistinctColumnValues(datasetId, columnName)),
        getMinMaxColumnValues: (datasetId, columnName) => dispatch(datasetsActions.getMinMaxColumnValues(datasetId, columnName))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StyleProperty));
