import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { history } from "../../../store";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import DragDropIcon from "@material-ui/icons/SaveAlt";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Tabs, Tab } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";

import FileUpload from "../../../components/fileUpload/fileUpload";

import * as ValidationUtils from "../../../utils/validationUtils";

import { kml } from "@tmcw/togeojson";

import * as datasetsActions from "../../../actions/datasets";
import * as groupActions from "../../../actions/groupService";
import { toastr } from "react-redux-toastr";

const StyledTabs = withStyles({
    indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent"
    }
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        "&:focus": {
            opacity: 1
        }
    },
    selected: {
        backgroundColor: theme.palette.background
    }
}))((props) => <Tab {...props} />);

function InvalidColumnName(property) {
    return (
        <div className="file-error">
            <div className="title">Column naming error</div>
            <div className="text">
                A column cannot be named <div className="highlight">{property}</div>
            </div>
        </div>
    );
}

const styles = (theme) => ({});

let geojsonData;

class CreateDatasetModal extends Component {
    state = {
        name: "",
        schemaName: "",
        schemaNames: [],
        tableName: "",
        groups: [],
        generateCache: true,
        page: "upload",
        files: [],
        multiFileMode: false,
        validated: { name: true, schemaName: true, tableName: true, groups: true },
        fileErrors: [],
        uploading: false
    };

    componentDidMount() {
        this.props.getGroups().then((res) => {
            this.setState({
                groups: res.result.map((x) => {
                    return { ...x, checked: false };
                })
            });
        });
        this.props.getSchemaNames().then((res) => {
            this.setState({
                schemaNames: res.result
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open != this.props.open) {
            this.setState({
                name: "",
                groups: this.state.groups.map((x) => {
                    return { ...x, checked: false };
                }),
                page: "upload",
                validated: { name: true, schemaName: true, tableName: true, groups: true },
                fileErrors: []
            });
        }
    }

    //Upload of shapefile where there is mutiple files
    onFilesChanged = (files) => {
        let name = files[0].name.split(".")[0];

        this.setState({
            files: files,
            multiFileMode: true,
            name: name,
            tableName: name.toLowerCase(),
            validated: {
                ...this.state.validated,
                name: true,
                tableName: ValidationUtils.validatePostgresIdentifier(name)
            }
        });
    };

    //Upload of Geojson, kml, csv etc. that gets converted to in-memmory geojson
    onDataChanged = (data, file) => {
        let name = file.name.split(".")[0];

        geojsonData = data;
        this.setState({
            multiFileMode: false,
            name: name,
            tableName: name.toLowerCase(),
            validated: {
                ...this.state.validated,
                name: true,
                tableName: ValidationUtils.validatePostgresIdentifier(name)
            }
        });
    };

    onFileError = (errors) => {
        this.setState({
            fileErrors: errors
        });
    };

    onUploadClicked = () => {
        if (!this.validateInfo()) {
            return;
        }

        let newDataset = {
            name: this.state.name,
            tableName: this.state.tableName,
            schemaName: this.state.schemaName,
            groups: this.state.groups.map(x => x.id),
            generateCache: this.state.generateCache
        };

        this.setState({
            uploading: true
        });

        if (this.state.multiFileMode) {
            this.props.createDatasetFromShapeFile(this.state.files, newDataset).then(this.onUploadSuccess).catch(this.onUploadError);
        } else {
            this.props.createDatasetFromMemory(geojsonData, newDataset).then(this.onUploadSuccess).catch(this.onUploadError);
        }
    };

    onUploadSuccess = (res) => {
        this.setState({
            name: "",
            groups: this.state.groups.map((x) => {
                return { ...x, checked: false };
            }),
            page: "info",
            validated: { name: true, groups: true }
        });

        toastr.success("Success", "Dataset uploaded");

        history.push("/datasets/edit/" + res.result.id);
    }

    onUploadError = (err) => {
        this.setState({
            uploading: false
        });

        toastr.error("Error", err.response.data.error);
    }

    validateInfo() {
        let nameValid = this.state.name != "";
        let schemaValid = this.state.schemaName != "";
        let tableNameValid = ValidationUtils.validatePostgresIdentifier(this.state.tableName);
        let groupValid = this.state.groups.some((x) => x.checked);

        this.setState({
            validated: {
                ...this.state.validated,
                name: nameValid,
                groups: groupValid,
                schemaName: schemaValid,
                tableName: tableNameValid
            }
        });

        return nameValid && groupValid && tableNameValid && schemaValid;
    }

    nextPage = () => {
        this.changePage("info");
    };

    changePage = (page) => {
        this.setState({
            page: page
        });
    };

    changeDatasetName(e) {
        let nameValid = e.target.value != "";

        this.setState({
            name: e.target.value,
            validated: {
                ...this.state.validated,
                name: nameValid
            }
        });
    }

    changeSchemaName = (e) => {
        this.setState({
            schemaName: e.target.value,
            validated: {
                ...this.state.validated,
                schemaName: true
            }
        });
    };

    changeTableName(e) {
        let valid = ValidationUtils.validatePostgresIdentifier(e.target.value);

        this.setState({
            tableName: e.target.value.toLowerCase(),
            validated: {
                ...this.state.validated,
                tableName: valid
            }
        });
    }

    onToggleCache = (e) => {
        this.setState({
            generateCache: !this.state.generateCache
        });
    };

    handleGroupChange(prop) {
        let newGroups = this.state.groups.map((x) => {
            if (x.name == prop) {
                return { ...x, checked: !x.checked };
            }
            return x;
        });

        this.setState({
            groups: newGroups,
            validated: {
                ...this.state.validated,
                groups: newGroups.some((x) => x.checked)
            }
        });
    }

    render() {
        let { classes } = this.props;

        let schemaNames = this.state.schemaNames.map((schema, index) => {
            return (
                <MenuItem key={index} value={schema}>
                    {schema}
                </MenuItem>
            );
        });

        // let fileErrors = this.state.fileErrors.map((error, index) => {
        //     return (
        //         <div className="file-error">
        //             <div className="title">{error.title}</div>
        //             <div className="text">
        //                 The column <div className="highlight">{error.text}</div> is invalid
        //             </div>
        //         </div>
        //     );
        // });

        return (
            <div className="">
                <Modal
                    className="atlas-modal create-dataset-modal"
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500
                    }}
                >
                    <Fade in={this.props.open}>
                        <div className="container">
                            <Typography variant="h6" id="transition-modal-title" className="header">
                                Upload Dataset
                            </Typography>
                            {this.state.uploading && <LinearProgress className="no-margin-progress" />}
                            <StyledTabs value={this.state.page} TabIndicatorProps={<div />}>
                                <StyledTab label="upload" value="upload" />
                                <StyledTab label="info" onClick={() => this.changePage("info")} value="info" />
                            </StyledTabs>
                            {this.state.fileErrors}
                            {this.state.page === "info" && (
                                <div className="page">
                                    <div className="section">
                                        <Typography variant="body1">Dataset name</Typography>
                                        <TextField
                                            id="full-width"
                                            inputProps={{ autoComplete: "no" }}
                                            error={!this.state.validated.name}
                                            placeholder="Name of the map"
                                            value={this.state.name}
                                            onChange={(e) => this.changeDatasetName(e)}
                                            fullWidth
                                            margin="none"
                                        />
                                    </div>
                                    <div className="section">
                                        <Typography variant="body1">Schema</Typography>
                                        <Select value={this.state.schemaName} error={!this.state.validated.schemaName} onChange={this.changeSchemaName} fullWidth>
                                            {schemaNames}
                                        </Select>
                                    </div>
                                    <div className="section">
                                        <Typography variant="body1">Table name</Typography>
                                        <TextField
                                            id="full-width"
                                            inputProps={{ autoComplete: "no" }}
                                            error={!this.state.validated.tableName}
                                            placeholder="Name of the map"
                                            value={this.state.tableName}
                                            onChange={(e) => this.changeTableName(e)}
                                            fullWidth
                                            margin="none"
                                        />
                                        {!this.state.validated.tableName && <FormHelperText error>Table name can only contain [A-Z] [0-9] "_"</FormHelperText>}
                                    </div>
                                    <div className="section">
                                        <Typography variant="body1">Dataset owners</Typography>
                                        {this.state.groups.map((dataset, index) => {
                                            return (
                                                <FormControlLabel
                                                    control={<Checkbox checked={dataset.checked} onChange={() => this.handleGroupChange(dataset.name)} />}
                                                    label={dataset.name}
                                                    key={index}
                                                />
                                            );
                                        })}
                                        {!this.state.validated.groups && <FormHelperText error>Select atleast one group</FormHelperText>}
                                    </div>

                                    <div className="section">
                                        <Typography variant="body1">Options</Typography>

                                        <FormControlLabel control={<Checkbox checked={this.state.generateCache} onChange={this.onToggleCache} />} label="cache" />

                                        {!this.state.validated.groups && <FormHelperText error>Select atleast one group</FormHelperText>}
                                    </div>

                                    <div className="button-container">
                                        <Button color="primary" variant="outlined" onClick={this.onUploadClicked} className={classes.button}>
                                            Upload
                                        </Button>
                                    </div>
                                </div>
                            )}

                            {this.state.page === "upload" && (
                                <div className="page">
                                    <FileUpload onDataChanged={this.onDataChanged} onFilesChanged={this.onFilesChanged} onError={this.onFileError} />
                                    <Button color="primary" variant="outlined" disabled={this.state.files.length == 0 && !geojsonData} onClick={this.nextPage} fullWidth className={"button"}>
                                        Next
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        createDataset: (file, datasetRequest) => dispatch(datasetsActions.createDataset(file, datasetRequest)),
        createDatasetFromMemory: (data, datasetRequest) => dispatch(datasetsActions.createDatasetFromMemory(data, datasetRequest)),
        createDatasetFromShapeFile: (files, datasetRequest) => dispatch(datasetsActions.createDatasetFromShapeFile(files, datasetRequest)),
        getSchemaNames: () => dispatch(datasetsActions.getSchemaNames()),
        getGroups: () => dispatch(groupActions.getGroups())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreateDatasetModal));
