import * as types from "./actionTypes";
import axiosClient from "./apiClient";

export function getDatasets() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_DATASETS });

        return axiosClient.get("dataset").then(function (res) {
            return dispatch({ type: types.FETCH_DATASETS_COMPLETE, result: res.data });
        });
    };
}

export function getDatasetsAdmin() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_DATASETS });

        return axiosClient.get("admin/dataset").then(function (res) {
            return dispatch({ type: types.FETCH_DATASETS_COMPLETE, result: res.data });
        });
    };
}

export function selectDataset(dataset) {
    return (dispatch) => {
        dispatch({ type: types.SELECT_DATASET, result: dataset });
    };
}

export function createDataset(file, datasetRequest) {
    return (dispatch) => {
        dispatch({ type: types.CREATE_DATASET });

        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", datasetRequest.name);
        formData.append("tableName", datasetRequest.tableName);
        formData.append("schemaName", datasetRequest.schemaName);
        formData.append("generateCache", datasetRequest.generateCache);

        for (var i = 0; i < datasetRequest.groups.length; i++) {
            formData.append("Groups[" + i + "]", datasetRequest.groups[i]);
        }

        return axiosClient
            .post("dataset", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(function (res) {
                return dispatch({ type: types.CREATE_DATASET_COMPLETE, result: res.data });
            });
    };
}

export function createDatasetFromShapeFile(files, datasetRequest) {
    return (dispatch) => {
        dispatch({ type: types.CREATE_DATASET });

        let formData = new FormData();

        formData.append("name", datasetRequest.name);
        formData.append("tableName", datasetRequest.tableName);
        formData.append("schemaName", datasetRequest.schemaName);
        formData.append("generateCache", datasetRequest.generateCache);

        for (var i = 0; i < files.length; i++) {
            formData.append("Files", files[i]);
        }

        for (var i = 0; i < datasetRequest.groups.length; i++) {
            formData.append("Groups[" + i + "]", datasetRequest.groups[i]);
        }

        return axiosClient
            .post("dataset/shapefile", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(function (res) {
                return dispatch({ type: types.CREATE_DATASET_COMPLETE, result: res.data });
            });
    };
}

export function createDatasetFromMemory(data, datasetRequest) {
    return (dispatch) => {
        dispatch({ type: types.CREATE_DATASET });

        let blob = new Blob([JSON.stringify(data)]);

        let formData = new FormData();

        formData.append("file", blob, "data.geojson");
        formData.append("name", datasetRequest.name);
        formData.append("tableName", datasetRequest.tableName);
        formData.append("schemaName", datasetRequest.schemaName);
        formData.append("generateCache", datasetRequest.generateCache);

        for (var i = 0; i < datasetRequest.groups.length; i++) {
            formData.append("Groups[" + i + "]", datasetRequest.groups[i]);
        }

        return axiosClient
            .post("dataset", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(function (res) {
                return dispatch({ type: types.CREATE_DATASET_COMPLETE, result: res.data });
            });
    };
}

export function updateDataset(id, updateRequest) {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_DATASET });

        return axiosClient.put("dataset/meta/" + id, updateRequest).then(function (res) {
            dispatch({ type: types.UPDATE_DATASET_COMPLETE, result: res.data });
        });
    };
}

export function deleteDataset(id) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_DATASET });

        return axiosClient.delete("dataset/meta/" + id).then(function (res) {
            dispatch({ type: types.DELETE_DATASET_COMPLETE, result: id });
        });
    };
}

export function getDatasetDetails(id) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_DATASET_DETAILS });

        return axiosClient.get("dataset/meta/" + id).then(function (res) {
            return dispatch({ type: types.FETCH_DATASET_DETAILS_COMPLETE, result: res.data });
        });
    };
}

export function getDatasetColumns(datasetId) {
    return (dispatch) => {
        dispatch({ type: "none" });

        return axiosClient.get(`dataset/${datasetId}/columns`).then(function (res) {
            return dispatch({ type: "none", result: res.data, id:datasetId });
        });
    };
}

export function updateDatasetColumns(datasetId, columns) {
    return (dispatch) => {
        dispatch({ type: "none" });

        return axiosClient.put(`dataset/${datasetId}/columns`, { columns }).then(function (res) {
            return dispatch({ type: "none", result: res.data });
        });
    };
}

export function getDistinctColumnValues(datasetId, column) {
    return (dispatch) => {
        dispatch({ type: "none" });

        return axiosClient.get(`dataset/${datasetId}/distinct?column=` + column).then(function (res) {
            return dispatch({ type: "none", result: res.data });
        });
    };
}

export function getMinMaxColumnValues(datasetId, column) {
    return (dispatch) => {
        dispatch({ type: "none" });

        return axiosClient.get(`dataset/${datasetId}/minmax?column=` + column).then(function (res) {
            return dispatch({ type: "none", result: res.data });
        });
    };
}

export function generateCache(datasetId) {
    return (dispatch) => {
        dispatch({ type: types.CREATE_CACHE });

        return axiosClient.post(`dataset/${datasetId}/generate`).then(function (res) {
            dispatch({ type: types.CREATE_CACHE_COMPLETE, result: res.data });
        });
    };
}

export function getSchemaNames() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_SCHEMA_NAMES });

        return axiosClient.get("dataset/schema").then(function (res) {
            return dispatch({ type: types.FETCH_SCHEMA_NAMES_COMPLETE, result: res.data });
        });
    };
}

export function getAsGeoJson(datasetId) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_DATASET_GEOJSON });

        return axiosClient.get(`dataset/${datasetId}/geojson`).then(function (res) {
            return dispatch({ type: types.FETCH_DATASET_GEOJSON_COMPLETE, result: res.data.features });
        });
    };
}
