import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import RemoveIcon from "@material-ui/icons/Remove";
import InputBase from "@material-ui/core/InputBase";
import MoreIcon from "@material-ui/icons/MoreHoriz";

function FloatProperty(props) {
    function handleChange(e) {
        let number = e.target.value.replace(/[^0-9a-zA-Z.\-]/g, "");
        props.onPropertyChanged(number);
    }

    return (
        <div className="property">
            <div className="property-content">
            {props.editable && <TextField className="textfield" onChange={props.onTitleChanged} value={props.title} />}
                {!props.editable && (
                    <Typography variant="body1" className="name">
                        {props.title}
                    </Typography>
                )}
                <div className="input-container">
                    <InputBase className="number" value={props.value} onChange={handleChange} />
                </div>
            </div>
        </div>
    );
}

export default FloatProperty;
