import * as types from "../actions/actionTypes";
import axiosClient from "../actions/apiClient";

export default function authMiddleware({ dispatch, getState }) {
    return (next) => (action) => {
        if (typeof action === "function") {
            let authState = getState().auth;

            if (isExpired(authState.expiresOn)) {
                // make sure we are not already refreshing the token
                if (!authState.authPromise) {
                    return authenticate(dispatch, authState).then(() => next(action));
                } else {
                    return authState.authPromise.then(() => next(action));
                }
            }
        }
        return next(action);
    };
}

function isExpired(expiresOn) {
    let currentTime = new Date();
    let expires_date = new Date(expiresOn);
    return currentTime > expires_date;
}

function authenticate(dispatch, state) {
    let refreshTokenPromise = axiosClient
        .post("auth/refresh")
        .then((resp) => authSuccess(resp, dispatch))
        .catch((ex) => authFail(ex, dispatch));

    dispatch({
        type: types.AUTHENTICATING,
        // we want to keep track of token promise in the state so that we don't     try to refresh the token again while refreshing is in process
        authPromise: refreshTokenPromise
    });

    return refreshTokenPromise;
}

function authSuccess(response, dispatch) {
    dispatch({
        type: types.AUTHENTICATED,
        result: response.data
    });
    return response
        ? Promise.resolve(response)
        : Promise.reject({
              message: "could not refresh token"
          });
}

function authFail(ex, dispatch) {
    console.log("exception refresh_token", ex);
    dispatch({
        type: types.AUTHENTICATING_FAILED
    });
    dispatch({
        type: types.AUTHENTICATING_FAILED,
        exception: ex
    });
}
