import React, { useState } from 'react';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MenuIcon from '@material-ui/icons/MoreVert';
import StorageIcon from '@material-ui/icons/Storage';
import ModifiedIcon from '@material-ui/icons/History';
import CreatedIcon from '@material-ui/icons/Schedule';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';

export default function Map(props) {

    const [anchorEl, setAnchorEl] = useState(null);

    const onMenuClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const onMenuClose = () => {
        setAnchorEl(null);
    };

    const onEditClick = () => {
        onMenuClose();
        props.onEditClick(props.map)
    }

    const onStyleClick = () => {
        onMenuClose();
        props.onStyleClick(props.map)
    }

    const onDeleteClick = () => {
        onMenuClose();
        props.onDeleteClick(props.map)

    }

    return (
        <div
            className={props.selected ? "map selected" : "map"}
            onDoubleClick={(e) => props.onDoubleClick(props.map)}
            onClick={() => props.OnClick(props.map)}
        >
            <div className="container">
                <div className="row-container">
                    <div className={props.map.cached ? "row-1 cached" : "row-1 uncached"} >

                        <Tooltip id="tooltip-top" placement="right" title={props.map.cached ? "cached" : "uncached"}>
                            <div className="name">
                                {props.map.name}
                            </div>
                        </Tooltip>

                        <Tooltip id="tooltip-top" title="Options">
                            <MenuIcon className="button" onClick={onMenuClick} />
                        </Tooltip>

                    </div>
                    {/* <div className="row-2">

                        <ModifiedIcon className="icon" />
                        <Tooltip id="tooltip-top" title={"modified: " + moment(props.map.modifiedUtc).format('MMMM Do YYYY, h:mm:ss a')}>
                            <div className="created">
                                {moment(props.map.modifiedUtc).fromNow()}
                            </div>
                        </Tooltip>

                        <CreatedIcon className="icon" />
                        <Tooltip id="tooltip-top" title={"created: " + moment(props.map.createdUtc).format('MMMM Do YYYY, h:mm:ss a')}>
                            <div className="created grow">
                                {moment(props.map.createdUtc).fromNow()}
                            </div>
                        </Tooltip>

                    </div> */}
                </div>

                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={onMenuClose}
                >
                    <MenuItem onClick={onEditClick} className="menu-button"><EditIcon className="icon" />Edit</MenuItem>
                    <MenuItem onClick={onStyleClick} className="menu-button"><EditIcon className="icon" />Style</MenuItem>
                    <MenuItem onClick={onEditClick} className="menu-button"><EditIcon className="icon" />Generate</MenuItem>
                    <MenuItem onClick={onDeleteClick} className="menu-button"><DeleteIcon className="icon" />Delete</MenuItem>
                </Menu>
            </div>
        </div>
    )
}