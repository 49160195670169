import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { history } from '../../../store';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import DragDropIcon from '@material-ui/icons/SaveAlt'
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

import * as datasetsActions from '../../../actions/datasets';
import * as mapServiceActions from '../../../actions/mapService';
import * as groupActions from '../../../actions/groupService';

const styles = theme => ({

});

class CreateMapModal extends Component {

    state = {
        name: '',
        datasets: [],
        groups: [],
        validated: { name: true, datasets: true, groups: true }
    }

    componentDidMount() {
        let datasetsPromise = this.props.getDatasets()
        let groupsPromise = this.props.getGroups()

        Promise.all([datasetsPromise, groupsPromise]).then(res => {

            let datasetsResult = res[0]
            let groupsResult = res[1]

            this.setState({
                datasets: datasetsResult.result.map(x => { return { ...x, checked: false } }),
                groups: groupsResult.result.map(x => { return { ...x, checked: false } })
            })
        })

    }
    changeMapName(e) {
        let nameValid = e.target.value != ""

        this.setState({
            name: e.target.value,
            validated:{
                ...this.state.validated,
                name:nameValid
            }
        })
    }

    handleDatasetChange(prop) {

        let newDatasets = this.state.datasets.map(x => {
            if (x.name == prop) {
                return { ...x, checked: !x.checked }
            }
            return x;
        })

        this.setState({
            datasets: newDatasets,
            validated:{
                ...this.state.validated,
                datasets:newDatasets.some(x => x.checked)
            }
        })
    }

    handleGroupChange(prop) {
        let newGroups = this.state.groups.map(x => {
            if (x.name == prop) {
                return { ...x, checked: !x.checked }
            }
            return x;
        })

        this.setState({
            groups: newGroups,
            validated:{
                ...this.state.validated,
                groups:newGroups.some(x => x.checked)
            }
        })
    }

    validateInfo() {
        let nameValid = this.state.name != '';
        let datasetsValid = this.state.datasets.some(x => x.checked);
        let groupValid = this.state.groups.some(x => x.checked);

        this.setState({
            validated: {
                name: nameValid,
                datasets: datasetsValid,
                groups: groupValid,
            }
        })

        return nameValid && datasetsValid && groupValid
    }

    createMap = () => {

        if (!this.validateInfo()) {
            return;
        }

        let request = {
            name: this.state.name,
            datasetIds: this.state.datasets.filter(x => x.checked).map(x => x.id),
            groupIds: this.state.groups.filter(x => x.checked).map(x => x.id)
        }

        this.props.createMap(request).then(res => {
            this.props.handleClose()
        });
    }

    render() {
        let { props } = this;
        let { classes } = props;
        return (
            <div>
                <Modal
                    className="atlas-modal create-map-modal"
                    open={props.open}
                    onClose={props.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={props.open}>
                        <div className="container">
                            <Typography variant="h6" id="transition-modal-title" className="header">Create map</Typography>
                            <div className="page" >
                                <div className="section">
                                    <Typography variant="body1" >Map name</Typography>
                                    <TextField
                                        id="full-width"
                                        placeholder="Name of the map"
                                        value={this.state.name}
                                        onChange={(e) => this.changeMapName(e)}
                                        fullWidth
                                        error={!this.state.validated.name}
                                        margin="none"
                                    />
                                </div>


                                <div className="section">
                                    <Typography variant="body1" >Datasets</Typography>
                                    {
                                        this.state.datasets.map((dataset, index) => {
                                            return (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={dataset.checked} onChange={() => this.handleDatasetChange(dataset.name)} />
                                                    }
                                                    label={dataset.name}
                                                    key={index}
                                                />
                                            )
                                        })

                                    }
                                    {!this.state.validated.datasets && <FormHelperText error>Select atleast one dataset</FormHelperText>}
                                </div>

                                <div className="section">
                                    <Typography variant="body1" >Owners</Typography>
                                    {
                                        this.state.groups.map((dataset, index) => {
                                            return (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={dataset.checked} onChange={() => this.handleGroupChange(dataset.name)} />
                                                    }
                                                    label={dataset.name}
                                                />
                                            )
                                        })

                                    }
                                    {!this.state.validated.groups && <FormHelperText error>Select atleast one group</FormHelperText>}
                                </div>

                                <div className='button-container'>
                                    <Button color="primary" variant="outlined" onClick={this.createMap}>
                                        Create
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getDatasets: () => dispatch(datasetsActions.getDatasets()),
        getGroups: () => dispatch(groupActions.getGroups()),
        createMap: (createMapRequest) => dispatch(mapServiceActions.createMap(createMapRequest)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(CreateMapModal));