import React, { Component } from "react";
import { connect } from "react-redux";
import * as groupServiceActions from "../../../actions/groupService";
import * as mapServiceActions from "../../../actions/mapService";
import * as userActions from "../../../actions/userService";
import * as datasetServiceActions from "../../../actions/datasets";
import * as permissionServiceActions from "../../../actions/permissionService";

import { withStyles } from "@material-ui/core/styles";
import { toastr } from "react-redux-toastr";

import Radio from "@material-ui/core/Radio";
import { Typography, Checkbox, Tooltip } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Tabs, Tab } from "@material-ui/core";

const StyledTabs = withStyles({
    indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent"
    }
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        "&:focus": {
            opacity: 1
        }
    },
    selected: {
        backgroundColor: theme.palette.secondaryBackground.main
    }
}))((props) => <Tab {...props} />);

const styles = (theme) => ({});

let permissionTypes = [
    { name: "Owner", value: 3 },
    { name: "Write", value: 2 },
    { name: "Read", value: 1 },
    { name: "None", value: 0 }
];

class GroupEditView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: "users",
            group: {
                name: ""
            },
            datasetPermissions: [],
            mapPermissions: [],
            users: []
        };
    }

    componentDidMount() {
        this.fetchGroup(this.props.match.params.groupId);
    }

    fetchGroup(groupId) {
        let groupPromise = this.props.getGroup(groupId);
        let mapsPromise = this.props.getMaps();
        let usersPromise = this.props.getUsers();
        let datasetsPromise = this.props.getDatasets();

        Promise.all([groupPromise, mapsPromise, usersPromise, datasetsPromise]).then((res) => {
            let groupData = res[0].result;
            let mapsData = res[1].result;
            let usersData = res[2].result;
            let datasetsData = res[3].result;

            let groupDatasetsLookup = groupData.datasetPermissions.reduce((a, b) => {
                a[b.id] = "";
                return a;
            }, {});

            for (let i = 0; i < datasetsData.length; i++) {
                let dataset = datasetsData[i];
                if (!groupDatasetsLookup.hasOwnProperty(dataset.id)) {
                    groupData.datasetPermissions.push({
                        cached: dataset.cached,
                        id: dataset.id,
                        maxZoom: dataset.maxZoom,
                        minZoom: dataset.minZoom,
                        name: dataset.name,
                        permissionType: 0
                    });
                }
            }

            let groupMapsLookup = groupData.mapPermissions.reduce((a, b) => {
                a[b.id] = "";
                return a;
            }, {});

            for (let i = 0; i < mapsData.length; i++) {
                let map = mapsData[i];
                if (!groupMapsLookup.hasOwnProperty(map.id)) {
                    groupData.mapPermissions.push({
                        cached: map.cached,
                        id: map.id,
                        name: map.name,
                        permissionType: 0
                    });
                }
            }

            let groupUsersLookup = groupData.users.reduce((a, b) => {
                a[b.id] = "";
                return a;
            }, {});

            let users = [];

            for (let i = 0; i < usersData.length; i++) {
                let user = usersData[i];
                if (!groupUsersLookup.hasOwnProperty(user.id)) {
                    users.push({
                        id: user.id,
                        username: user.username,
                        roles: user.roles,
                        isMember: false
                    });
                } else {
                    users.push({
                        id: user.id,
                        username: user.username,
                        roles: user.roles,
                        isMember: true
                    });
                }
            }

            this.setState({
                group: { id: groupData.id, name: groupData.name },
                datasetPermissions: groupData.datasetPermissions,
                mapPermissions: groupData.mapPermissions,
                users: users
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.groupId !== prevProps.match.params.groupId) {
            this.fetchGroup(this.props.match.params.groupId);
        }
    }

    handleDatasetPermissionChange = (dataset, permissionType) => {
        let promise;

        if (dataset.permissionType == 0) {
            promise = this.props.addDatasetPermission(this.state.group.id, dataset.id, permissionType);
        } else if (permissionType == 0) {
            promise = this.props.removeDatasetPermission(this.state.group.id, dataset.id);
        } else {
            promise = this.props.updateDatasetPermission(this.state.group.id, dataset.id, permissionType);
        }

        promise.then(
            (res) => {
                toastr.success("Success", "Dataset permission updated");
            },
            (err) => {
                toastr.error("Error", err.message);
            }
        );

        this.setState({
            datasetPermissions: this.state.datasetPermissions.map((x) => {
                if (x.id == dataset.id) {
                    return {
                        ...x,
                        permissionType: permissionType
                    };
                }
                return x;
            })
        });
    };

    handleMapPermissionChange = (map, permissionType) => {
        let promise;

        if (map.permissionType == 0) {
            promise = this.props.addMapPermission(this.state.group.id, map.id, permissionType);
        } else if (permissionType == 0) {
            promise = this.props.removeMapPermission(this.state.group.id, map.id);
        } else {
            promise = this.props.updateMapPermission(this.state.group.id, map.id, permissionType);
        }

        promise.then(
            (res) => {
                toastr.success("Success", "Map permission updated");
            },
            (err) => {
                toastr.error("Error", err.message);
            }
        );

        this.setState({
            mapPermissions: this.state.mapPermissions.map((x) => {
                if (x.id == map.id) {
                    return {
                        ...x,
                        permissionType: permissionType
                    };
                }
                return x;
            })
        });
    };

    handleGroupUserChange = (user) => {
        let promise;

        if (user.isMember) {
            promise = this.props.removeGroupUser(this.state.group.id, user.id);
        } else {
            promise = this.props.addGroupUser(this.state.group.id, user.id);
        }

        promise.then(
            (res) => {
                toastr.success("Success", "User permission updated");
            },
            (err) => {
                toastr.error("Error", err.message);
            }
        );

        user.isMember = !user.isMember;

        let newUsers = this.state.users.map((x) => {
            if (x.id === user.id) {
                return user;
            }
            return x;
        });

        this.setState({
            users: newUsers
        });
    };

    changePage = (e, value) => {
        this.setState({
            page: value
        });
    };

    changeGroupName = (e) => {
        this.setState({
            group: {
                ...this.state.group,
                name: e.target.value
            }
        });
    };

    render() {
        let { classes, match } = this.props;

        let users = this.state.users.map((user, index) => {
            return (
                <div key={index} className="dataset">
                    <div className="name">{user.username}</div>

                    <div key={index} className="checkbox-container">
                        <div className="radio-label">Member</div>
                        <Checkbox checked={user.isMember} onChange={() => this.handleGroupUserChange(user)} />
                    </div>
                </div>
            );
        });

        let datasets = this.state.datasetPermissions.map((dataset, index) => {
            return (
                <div key={index} className="dataset">
                    <Tooltip title={dataset.name}>
                        <div className="name">{dataset.name}</div>
                    </Tooltip>

                    {permissionTypes.map((permission, index) => {
                        return (
                            <div key={index} className="checkbox-container">
                                <Radio checked={dataset.permissionType == permission.value} onChange={() => this.handleDatasetPermissionChange(dataset, permission.value)} />
                                <div className="radio-label">{permission.name}</div>
                            </div>
                        );
                    })}
                </div>
            );
        });

        let maps = this.state.mapPermissions.map((map, index) => {
            return (
                <div key={index} className="dataset">
                     <Tooltip title={map.name}>
                        <div className="name">{map.name}</div>
                    </Tooltip>
                    {permissionTypes.map((permission, index) => {
                        return (
                            <div key={index} className="checkbox-container">
                                <Radio checked={map.permissionType == permission.value} onChange={() => this.handleMapPermissionChange(map, permission.value)} />
                                <div className="radio-label">{permission.name}</div>
                            </div>
                        );
                    })}
                </div>
            );
        });

        return (
            <div className="group-edit-view">
                <div className="header">
                    <Typography variant="subtitle1">Edit Group</Typography>
                </div>

                <div className="container">
                    <div className="section">
                        <FormLabel component="legend">Group Name</FormLabel>
                        <TextField id="full-width" placeholder="Name of the group" value={this.state.group.name} onChange={this.changeGroupName} fullWidth margin="dense" />
                    </div>
                    <div className="section">
                        <StyledTabs value={this.state.page} onChange={this.changePage}>
                            <StyledTab label="users" value="users" />
                            <StyledTab label="datasets" value="datasets" />
                            <StyledTab label="maps" value="maps" />
                        </StyledTabs>
                        {this.state.page === "users" && (
                            <div className="list">
                                <div className="datasets">{users}</div>
                            </div>
                        )}
                        {this.state.page === "datasets" && (
                            <div className="list">
                                <div className="datasets">{datasets}</div>
                            </div>
                        )}
                        {this.state.page === "maps" && (
                            <div className="list">
                                <div className="datasets">{maps}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getGroup: (groupId) => dispatch(groupServiceActions.getGroup(groupId)),
        getDatasets: () => dispatch(datasetServiceActions.getDatasetsAdmin()),
        getMaps: () => dispatch(mapServiceActions.getMapsAdmin()),
        addDatasetPermission: (groupId, datasetId, permissionType) => dispatch(permissionServiceActions.addDatasetPermission(groupId, datasetId, permissionType)),
        updateDatasetPermission: (groupId, datasetId, permissionType) => dispatch(permissionServiceActions.updateDatasetPermission(groupId, datasetId, permissionType)),
        removeDatasetPermission: (groupId, datasetId) => dispatch(permissionServiceActions.removeDatasetPermission(groupId, datasetId)),
        addMapPermission: (groupId, mapId, permissionType) => dispatch(permissionServiceActions.addMapPermission(groupId, mapId, permissionType)),
        updateMapPermission: (groupId, mapId, permissionType) => dispatch(permissionServiceActions.updateMapPermission(groupId, mapId, permissionType)),
        removeMapPermission: (groupId, mapId) => dispatch(permissionServiceActions.removeMapPermission(groupId, mapId)),
        getUsers: () => dispatch(userActions.getUsers()),
        addGroupUser: (groupId, userId) => dispatch(groupServiceActions.addGroupUser(groupId, userId)),
        removeGroupUser: (groupId, userId) => dispatch(groupServiceActions.removeGroupUser(groupId, userId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupEditView));
