import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import RemoveIcon from "@material-ui/icons/Clear";
import ColorPopover from "../colorPopover";
import TextField from '@material-ui/core/TextField';

function ColorProperty(props) {
    return (
        <div className="property" onClick={props.onClick}>
            <div className="property-content">
                {props.editable && <TextField className="textfield" onChange={props.onTitleChanged} value={props.title} />}
                {!props.editable && (
                    <Typography variant="body1" className="name">
                        {props.title}
                    </Typography>
                )}
                <ColorPopover color={props.value} onColorChanged={(color) => props.onPropertyChanged(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)} />
            </div>
        </div>
    );
}

export default ColorProperty;
