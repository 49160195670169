import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import RcTooltip from "rc-tooltip";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import * as datasetsActions from "../../../actions/datasets";
import * as tileActions from "../../../actions/tile";
import * as mapActions from "../../../actions/map";
import { history } from "../../../store";
import { toastr } from "react-redux-toastr";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Tabs, Tab } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import colors from "../../../scss/variables.scss";

import * as TileUtil from "../../../utils/tileUtils";

const StyledTabs = withStyles({
    root: {
        height: 32,
        minHeight: 32
    },
    indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent"
    }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
    root: {
        height: 32,
        minHeight: 32,
        width: "50%",
        padding: 0,

        "&:focus": {
            opacity: 1
        }
    },
    selected: {
        backgroundColor: theme.palette.background
    }
}))(props => <Tab {...props} />);

const styles = theme => ({
    root: {}
});

const Handle = Slider.Handle;

const handle = props => {
    const { value, dragging, index, ...restProps } = props;
    return (
        <RcTooltip prefixCls="rc-slider-tooltip" overlay={value} placement="top" key={index}>
            <Handle value={value} {...restProps} />
        </RcTooltip>
    );
};

class DatasetEditView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataset: {
                datasetName: "",
                cached: true,
                minZoom: 10,
                maxZoom: 24
            },
            columns: [],
            allColumns: false,
            dataChanged: false,
            tileCount: 0,
            page: "info"
        };
    }

    componentDidMount() {
        let datasetPromise = this.props.getDatasetDetails(this.props.match.params.datasetId);
        let columnsPromise = this.props.getDatasetColumns(this.props.match.params.datasetId);

        Promise.all([datasetPromise, columnsPromise]).then(res => {
            let datasetResult = res[0].result;
            let columnsResult = res[1].result;

            this.init(datasetResult, columnsResult);
        });
    }

    init(dataset, columns) {
        let layerType = this.getLayerType(dataset.geometryType);

        let bbox = {
            minLon: dataset.bounds.coordinates[0][0][0],
            minLat: dataset.bounds.coordinates[0][0][1],
            maxLon: dataset.bounds.coordinates[0][2][0],
            maxLat: dataset.bounds.coordinates[0][2][1]
        };
        let tileCount = TileUtil.getTileCount(bbox, dataset.minZoom, dataset.maxZoom);
        this.setState({
            columns: columns,
            dataset: dataset,
            layerType: layerType,
            tileCount: tileCount,
            bbox: bbox
        });

        if (dataset.cached){
            this.props.addMapLayer({
                sourceId: dataset.id,
                layerId: dataset.id,
                sourceName: dataset.name,
                type: layerType,
                minZoom: dataset.minZoom,
                maxZoom: dataset.maxZoom
            });
    
            this.props.addMapPaint({
                layerId: dataset.id,
                properties: [
                    {
                        name: layerType + "-color",
                        value: colors.primaryColor
                    }
                ]
            });
        }
      

        // this.props.mapJumpTo({
        //     zoom: 14,
        //     center: [12.529389, 55.678285],
        // })
        this.props.mapFitBounds([dataset.bounds.coordinates[0][0], dataset.bounds.coordinates[0][2]], {
            padding: { top: 45, bottom: 45, left: 45, right: 45 },
            animate: false
        });
    }

    getLayerType(type) {
        switch (type) {
            case "POLYGON":
            case "MULTIPOLYGON":
                return "fill";
            case "POINT":
            case "MULTIPOINT":
                return "circle";
            case "LINESTRING":
            case "MULTILINESTRING":
                return "line";
        }
    }

    componentWillUnmount() {
        this.props.removeMapLayer({ layerId: this.props.match.params.datasetId });
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.props.getDatasetDetails(this.props.match.params.id).then(x => this.getDetails(x));
        }
    }

    changeColumnsToInclude = (item, event) => {
        item.included = !item.included;

        let newColumns = this.state.columns.map(x => {
            if (x.id === item.id) {
                return item;
            }
            return x;
        });

        this.setState({ columns: newColumns, dataChanged: true });
    };

    toggleAllColumns = () => {
        let checked = !this.state.allColumns;

        let newColumns = this.state.columns.map(x => {
            return { ...x, included: checked };
        });

        this.setState({ columns: newColumns, allColumns: checked, dataChanged: true });
    };

    updateDataset = () => {
        let dataset = {
            name: this.state.dataset.name,
            minZoom: this.state.dataset.minZoom,
            maxZoom: this.state.dataset.maxZoom
        };

        this.props.updateDatasetColumns(this.props.data.dataset.id, this.state.columns).then(() => {
            toastr.success("Success", "Dataset updated");
            this.setState({
                dataChanged: false
            });
        });

        this.props.updateDataset(this.props.data.dataset.id, dataset).then(() => {
            toastr.success("Success", "Dataset updated");
            this.setState({
                dataset: {
                    ...this.state.dataset,
                    cached: false
                },
                dataChanged: false
            });
        });
    };

    generateCache = () => {
        this.props.generateCache(this.props.data.dataset.id).then(() => {
            toastr.success(`Cache generated for ${this.state.dataset.name} `, `Zoom Level ${this.state.dataset.minZoom} - ${this.state.dataset.maxZoom}`);
            this.props.removeMapLayer({ layerId: this.props.match.params.datasetId }).then(res => {
                this.props.addMapLayer({
                    sourceId: this.props.match.params.datasetId,
                    layerId: this.props.match.params.datasetId,
                    sourceName: this.state.dataset.name,
                    type: this.state.layerType,
                    minZoom: this.state.dataset.minZoom,
                    maxZoom: 24
                });

                this.props.addMapPaint({
                    layerId: this.props.match.params.datasetId,
                    properties: [
                        {
                            name: this.state.layerType + "-color",
                            value: colors.primaryColor
                        }
                    ]
                });

                this.setState({
                    dataset: {
                        ...this.state.dataset,
                        cached: true
                    }
                });
            });
        });
    };

    changeDatasetName = event => {
        this.setState({
            dataset: {
                ...this.state.dataset,
                name: event.target.value
            },
            dataChanged: true
        });
    };

    changeColumnName = (e, column) => {
        column.prettyName = e.target.value;

        let newColumns = this.state.columns.map(x => {
            if (x.id === column.id) {
                return column;
            }
            return x;
        });

        this.setState({ columns: newColumns, dataChanged: true });
    };

    zoomSliderChange = values => {
        let tileCount = TileUtil.getTileCount(this.state.bbox, values[0], values[1]);

        this.setState({
            dataset: {
                ...this.state.dataset,
                minZoom: values[0],
                maxZoom: values[1]
            },

            dataChanged: true,
            tileCount: tileCount
        });
    };

    changePage = page => {
        this.setState({
            page: page
        });
    };

    render() {
        let columnCheckboxes = this.state.columns.map((item, index) => {
            return (
                <div className="checkbox" key={index}>
                    <div className="flex" onClick={e => this.changeColumnsToInclude(item, e)}>
                        <div className="box">
                            <Checkbox checked={item.included} />
                        </div>
                        <div className="label">{item.name}</div>
                    </div>

                    <div className="seperator">As</div>
                    <div className="field">
                        <TextField
                            id="full-width"
                            placeholder="Name of the dataset"
                            value={item.prettyName}
                            onChange={e => this.changeColumnName(e, item)}
                            fullWidth
                            margin="none"
                        />
                    </div>
                </div>
            );
        });

        let { classes } = this.props;

        return (
            <div className="sidebar-container dataset-details">
                <AppBar position="static">
                    <Toolbar className="toolbar">
                        <img src="/Logo.svg"></img>
                    </Toolbar>
                </AppBar>
                <div className="header">
                    <Typography variant="h6" className={classes.title}>
                        Datasets
                    </Typography>
                    {!this.props.data.fetching && (
                        <Tooltip title="generate cache" id="tooltip-top">
                            <div onClick={this.generateCache} className={this.state.dataset.cached ? "cache-status-container cached" : "cache-status-container uncached"}>
                                <Typography variant="title2">{this.state.dataset.cached ? "cached" : "uncached"}</Typography>
                            </div>
                        </Tooltip>
                    )}
             
                </div>            
                {(this.props.data.fetching || this.props.data.generatingCache) && <LinearProgress className="no-margin-progress" />}

                {!this.props.data.fetching && (
                    <StyledTabs value={this.state.page} TabIndicatorProps={<div />}>
                        <StyledTab label="basic info" onClick={() => this.changePage("info")} value="info" />
                        <StyledTab label="advanced options" onClick={() => this.changePage("advanced")} value="advanced" />
                    </StyledTabs>
                )}
                {!this.props.data.fetching && (
                    <div className="container">
                        {this.state.page === "info" && (
                            <div className="page">
                                <div className="section border">
                                    <Typography variant="subtitle1" className="subtitle">
                                        Name
                                    </Typography>
                                    <div className="text-container">
                                        <TextField
                                            id="full-width"
                                            variant="outlined"
                                            placeholder="Name of the dataset"
                                            value={this.state.dataset.name}
                                            onChange={this.changeDatasetName}
                                            fullWidth
                                            margin="none"
                                        />
                                    </div>
                                </div>
                                <div className="section border">
                                    <div className="flex">
                                        <div className="zoom-levels-container">
                                            <Typography variant="subtitle1" className="subtitle">
                                                Id
                                            </Typography>
                                            <div className="value">{this.state.dataset.id}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section border">
                                    <div className="flex">
                                        <div className="zoom-levels-container">
                                            <Typography variant="subtitle1" className="subtitle">
                                                Rows
                                            </Typography>
                                            <div className="value">{this.state.dataset.rowCount} rows</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section border">
                                    <div className="flex">
                                        <div className="zoom-levels-container">
                                            <Typography variant="subtitle1" className="subtitle">
                                                Zoom
                                            </Typography>
                                            <div className="value">
                                                {this.state.dataset.minZoom} - {this.state.dataset.maxZoom}
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="generated-tiles">
                            <div>{this.state.tileCount}</div>
                            <div className="generated-tiles-header">Number of generated tiles</div>
                        </div>
                        <div className="slider-container">
                            <Range
                                max={24}
                                min={1}
                                handle={handle}
                                pushable={2}
                                value={[this.state.minZoom, this.state.maxZoom]}
                                onChange={value => this.zoomSliderChange(value)}
                            />
                            <div className="slider-footer">
                                <span>1</span>
                                <span>24</span>
                            </div>
                        </div> */}
                                </div>
                                <div className="section border scroll">
                                    <Typography variant="subtitle1" className="subtitle">
                                        Properties
                                    </Typography>
                                    <div className="all-checkbox" onClick={this.toggleAllColumns}>
                                            <div className="all-box">
                                                <Checkbox checked={this.state.allColumns} />
                                            </div>
                                            <div className="label">All</div>
                                        </div>
                                    <div className="checkbox-container">
                                      
                                        {columnCheckboxes}
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.state.page === "advanced" && (
                            <div className="page">
                                <div className="section border">
                                    <div className="generated-tiles">
                                        <div>{this.state.tileCount}</div>
                                        <div className="generated-tiles-header">Number of generated tiles</div>
                                    </div>
                                    <div className="slider-container">
                                        <Range
                                            max={24}
                                            min={1}
                                            handle={handle}
                                            pushable={2}
                                            value={[this.state.dataset.minZoom, this.state.dataset.maxZoom]}
                                            onChange={value => this.zoomSliderChange(value)}
                                        />
                                        <div className="slider-footer">
                                            <span>1</span>
                                            <span>24</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="section">
                            <Button className="back-btn" variant="outlined" color="primary" onClick={() => history.push("/datasets")}>
                                back
                            </Button>

                            <Button className="primary-btn" disabled={!this.state.dataChanged} variant="outlined" color="primary" onClick={e => this.updateDataset()}>
                                Save changes
                            </Button>

                            {/* {!this.state.dataChanged && (
                            <Button className="primary-btn" variant="outlined" color="primary" onClick={() => this.generateCache()}>
                                Generate
                            </Button>
                        )} */}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.datasetDetails
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getDatasetDetails: id => dispatch(datasetsActions.getDatasetDetails(id)),
        getDatasetColumns: id => dispatch(datasetsActions.getDatasetColumns(id)),
        updateDataset: (id, dataset) => dispatch(datasetsActions.updateDataset(id, dataset)),
        updateDatasetColumns: (id, columns) => dispatch(datasetsActions.updateDatasetColumns(id, columns)),
        getTileCount: (id, minZoom, maxZoom) => dispatch(tileActions.getTileCount(id, minZoom, maxZoom)),
        addMapLayer: layer => dispatch(mapActions.addLayer(layer)),
        addMapPaint: paint => dispatch(mapActions.addPaint(paint)),
        generateCache: dataset => dispatch(datasetsActions.generateCache(dataset)),
        removeMapLayer: layer => dispatch(mapActions.removeLayerAsync(layer)),
        mapJumpTo: options => dispatch(mapActions.jumpTo(options)),
        mapFitBounds: (bbox, options) => dispatch(mapActions.fitBounds(bbox, options))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatasetEditView));
